import React, { Dispatch, SetStateAction } from "react";
import swal from "sweetalert";
import CardBlog from "../../components/CardBlog";
import { Blog } from "../../models/entities/Blog/blog";
import api from "../../services/api";

import { Container } from "./styles";

interface IProps {
  blogs: Blog[];

  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const BlogList: React.FC<IProps> = ({ blogs, setShouldSearch }) => {
  const handleAddComment = async (
    comment: string,
    blog_id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/blogComment", {
        blog_id,
        comment,
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao realizar registro no blog";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDeleteComment = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/blogComment/${id}`);
      swal("Comentário excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao excluír comentário";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/blog/${id}`);
      swal("Post do blog excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao excluir postagem";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleLike = async ({
    parent_id,
    like,
    deslike,
  }: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/blogLike", {
        blog_id: parent_id,
        like,
        deslike,
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao registrar like";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  return (
    <Container>
      {blogs.map((blog) => (
        <CardBlog
          key={blog.id}
          blog={blog}
          parent_id={blog.id}
          setShouldSearch={setShouldSearch}
          handleAddComment={handleAddComment}
          handleDeleteComment={handleDeleteComment}
          handleDelete={handleDelete}
          handleLike={handleLike}
        />
      ))}
    </Container>
  );
};

export default BlogList;
