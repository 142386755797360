import React from "react";
import { BlogFiles } from "../../models/entities/Blog/blogFiles";
import { Container, Items, ICarousel, Images } from "./styles";

interface IProps {
  images: BlogFiles[];
}

const CarouselBlog: React.FC<IProps> = ({ images }) => {
  return (
    <Container>
      <ICarousel showArrows={false}>
        {images.map((image, index) => (
          <Items key={image.id}>
            <Images
              id="images-carousel"
              src={image.file_url}
              alt={`Imagem ${index + 1}`}
            />
          </Items>
        ))}
      </ICarousel>
    </Container>
  );
};

export default CarouselBlog;
