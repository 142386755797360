import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import api from "../../services/api";

import Upload from "../Upload";
import ButtonSave from "../ButtonSave";
import swal from "sweetalert";
import { v4 } from "uuid";

import {
  Container,
  Content,
  CloseIcon,
  TitleModal,
  UploadContainer,
  TitleContainer,
  Input,
  TitleContet,
  SaveButtonContainer,
  DescriptionContent,
  Textarea,
  ListImages,
  CardListImages,
  Preview,
  Remove,
} from "./styles";

interface IProp {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalNewBlog: React.FC<IProp> = ({
  setShouldSearch,
  setIsVisible,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<
    {
      url?: string;
      id?: string;
      file?: any;
    }[]
  >([]);
  const [title, setTitle] = useState<string | null>();
  const [description, setDescription] = useState<string | null>();
  const [image, setImage] = useState<File | null>(null);
  const modalRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsVisible(false);
    }
  };

  const handleSave = async (): Promise<void> => {
    setLoading(true);
    try {
      if (loading || !title || !description || files.length <= 0) {
        return swal(
          "Oops!",
          "Preencha os campos: título, descrição e faça o upload de imagem corretamente",
          "warning"
        );
      }
      const {
        data: {
          content: { id: blog_id },
        },
      } = await api.post("/blog", {
        title,
        description,
      });

      await Promise.all(
        files.map(async ({ file }) => {
          const imageToUpload = new FormData();

          imageToUpload.append("file", file);

          const {
            data: { location: file_url, key: s3_key, type: file_type },
          } = await api.post("/s3-upload/upload", imageToUpload);

          await api.post("/blogFiles", {
            blog_id,
            file_type,
            file_url,
            s3_key,
          });
        })
      );
      swal("Sucesso!", "Postagem cadastrada com sucesso.", "success");
      setIsVisible(false);
      setShouldSearch(true);
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";
      swal("", error, "Erro");
    } finally {
      setLoading(false);
    }
  };

  const onUploadFile = (payload) => {
    setFiles((oldValues) => [...oldValues, { id: v4(), file: payload[0] }]);
  };

  const onDeleteFile = (id?: string) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const maxSize = 10 * 1024 * 1024;

  return (
    <Container ref={modalRef} onClick={closeModal}>
      <Content>
        <TitleModal>
          <span>Nova postagem</span>
        </TitleModal>
        <TitleContainer>
          <TitleContet>
            <span>Título</span>
            <Input
              id="title-input"
              placeholder="Digite o título"
              onChange={({ target: { value } }) => setTitle(value)}
            />
          </TitleContet>

          <DescriptionContent>
            <span>Descrição</span>
            <Textarea
              id="description-textarea"
              onChange={({ target: { value } }) => setDescription(value)}
              placeholder="Informe uma descrição"
            />
          </DescriptionContent>

          <>
            <UploadContainer>
              <Upload
                disabled={files.length >= 10}
                onUpload={onUploadFile}
                image={image}
                setImage={setImage}
                maxFiles={10}
                typeFile={"image/*"}
                maxSize={maxSize}
              />
              <span>Proporção: 1080x1080</span>
            </UploadContainer>

            <ListImages>
              {files.map((file) => (
                <CardListImages key={file.id}>
                  <Preview
                    src={file.file ? URL.createObjectURL(file.file) : file.url}
                  />
                  <Remove
                    id="remove-button"
                    onClick={() => onDeleteFile(file.id)}
                  >
                    Excluir
                  </Remove>
                </CardListImages>
              ))}
            </ListImages>
          </>
        </TitleContainer>

        <SaveButtonContainer>
          <ButtonSave
            loading={loading}
            description="Salvar Upload"
            onClick={() => handleSave()}
          />
        </SaveButtonContainer>

        <CloseIcon
          id="close-button"
          aria-label="Close Modal"
          onClick={() => setIsVisible((prev) => !prev)}
        />
        {children}
      </Content>
    </Container>
  );
};

export default ModalNewBlog;
