import React, { Dispatch, SetStateAction, useRef } from "react";
import { Material } from "../../models/entities/Material/material";

import pdfImage from "../../assets/svg/pdfIcon.svg";

import {
  Container,
  Content,
  TitleModal,
  CloseIcon,
  Infos,
  ImageContainer,
  Titulo,
  Description,
  Image,
  Video,
} from "./styles";

interface IProp {
  index: number;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  material: Material;
}

const ModalPostsInfo: React.FC<IProp> = ({
  index,
  isVisible,
  setIsVisible,
  material,
}) => {
  const modalRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsVisible(false);
    }
  };

  return (
    <>
      {isVisible ? (
        <Container ref={modalRef} onClick={closeModal}>
          <Content>
            <TitleModal>Mais informações</TitleModal>
            <CloseIcon
              id="close-button"
              aria-label="Close Modal"
              onClick={() => setIsVisible((prev) => !prev)}
            />
            <Infos>
              <Titulo id={`title-${index}-container`}>{material.title}</Titulo>
              <Description id={`description-${index}-container`}>
                {material.description}
              </Description>
            </Infos>
            <ImageContainer>
              {material?.file_type.includes("pdf") ? (
                <Image src={pdfImage} alt={material.title} />
              ) : (
                <>
                  {material?.file_type.includes("video/mp4") ? (
                    <Video
                      id={`materialVideo-${index}-container`}
                      src={material.url}
                      controls
                    />
                  ) : (
                    <Image
                      id={`materialImage-${index}-container`}
                      src={material.url}
                      alt={material.title}
                    />
                  )}
                </>
              )}
            </ImageContainer>
          </Content>
        </Container>
      ) : null}
    </>
  );
};

export default ModalPostsInfo;
