import React, { useState, useEffect } from "react";
import Layout from "../containers/Layout";
import Home from "../pages/Home";
import Material from "../pages/Material";
import TutorialPage from "../pages/Tutorial";
import TablesAndManual from "../pages/TablesAndManual";
import Marketing from "../pages/Marketing";
import Login from "../pages/Login";
import Blog from "../pages/Blog";
import Calendar from "../pages/Calendar";
import { verifyPermission, isAuthenticated } from "../services/auth";

import { Switch, Route, RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component: any;
  permission?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, permission, ...rest } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const sendToLogin = () => {
      const link = document.createElement("a");
      link.href = process.env.REACT_APP_WEB_AUTH_URL as string;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const fetchPermissionWithRole = async () => {
      const response = await verifyPermission(permission);
      if (response) {
        setLoading(false);
      } else {
        sendToLogin();
      }
    };
    const fetchPermissionWithOutRole = async () => {
      const response = await isAuthenticated();
      if (response) {
        setLoading(false);
      } else {
        sendToLogin();
      }
    };
    if (permission) {
      fetchPermissionWithRole();
    } else {
      fetchPermissionWithOutRole();
    }
  }, [permission, Component]);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        loading ? (
          <></>
        ) : (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        )
      }
    />
  );
};

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute exact path="/material/:type" component={Material} />
    <PrivateRoute exact path="/table-manual" component={TablesAndManual} />
    <PrivateRoute exact path="/marketing" component={Marketing} />
    <PrivateRoute exact path="/blog" component={Blog} />
    <PrivateRoute exact path="/calendar" component={Calendar} />

    <PrivateRoute exact path="/tutorial/:type" component={TutorialPage} />
    <Route exact path="/login/:token" component={Login} />
  </Switch>
);

export default Routes;
