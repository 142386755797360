import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  background: var(--white);
  border-bottom: 1px solid var(--gray-200);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 5px;
`;

export const IconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  @media only screen and (max-width: 578px) {
    width: 17%;
  }
`;

export const Icon = styled.img`
  width: 4.3rem;
  height: 4.3rem;

  @media only screen and (max-width: 1600px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media only screen and (width: 1440px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media only screen and (max-width: 1366px) {
    width: 3rem;
    height: 3rem;
  }
  @media only screen and (max-width: 578px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 85%;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  color: var(--gray-700);

  @media only screen and (max-width: 1600px) {
    font-size: 1.25rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 1.25rem;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 578px) {
    font-size: 0.9rem;
  }
`;

export const Description = styled.p`
  font-size: 0.9rem;
  color: var(--gray-400);

  @media only screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }

  @media only screen and (width: 1440px) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 578px) {
    font-size: 0.7rem;
  }
`;
