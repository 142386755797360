import styled from "styled-components";

import { Add } from "../../styles/Icons";

export const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.6rem;
  background: var(--gray-700);
  color: var(--white);
  border-radius: 0.5rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  &:hover {
    filter: contrast(0.8);
  }

  @media only screen and (max-width: 578px) {
    border-radius: 50%;
    span {
      display: none;
    }
  }
`;

export const AddIcon = styled(Add)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.4rem;

  path {
    stroke: white;
  }

  @media only screen and (max-width: 578px) {
    margin-right: 0;
  }
`;
