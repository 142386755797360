import React, { useEffect } from "react";
import { withRouter, RouteComponentProps, useParams } from "react-router-dom";
import { login, setAccess } from "../../services/auth";
import janusAPI from "../../services/janusApi";

interface IProps extends RouteComponentProps {}

const Login: React.FC<IProps> = ({ history }) => {
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    async function fetchLogin() {
      login(token);
      const {
        data: { modules, permissions },
      } = await janusAPI.get("/user/access");
      setAccess(JSON.stringify(modules), JSON.stringify(permissions));
      history.replace("/");
    }
    fetchLogin();
  }, [token, history]);

  return <div />;
};

export default withRouter(Login);
