import styled, { css } from "styled-components";
import { Comment, Like, Dislike, TrashAlt, EditAlt } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 80%;
  margin-bottom: 10px;
  position: relative;

  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const ImagemContainer = styled.div`
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  margin-right: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  background: var(--white);

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const InfoPost = styled.div`
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  background: var(--white);
  flex-direction: column;

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const InfoUser = styled.div`
  width: 100%;
  height: 10%;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #cccccc47;
`;

export const AvatarContent = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;

  @media only screen and (max-width: 1366px) {
    width: 15%;
  }
`;

export const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;

  @media only screen and (max-width: 1366px) {
    width: 35px;
    height: 35px;
  }
`;

export const UserName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 80%;

  margin-left: 0.6rem;

  h3 {
    color: var(--gray-700);
    text-transform: capitalize;
    font-size: 1rem;
  }

  span {
    font-size: 0.9rem;
    color: var(--gray-500);
  }

  @media only screen and (max-width: 1366px) {
    width: 70%;

    h3 {
      font-size: 0.8rem;
    }

    span {
      font-size: 0.7rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 75%;
  width: 100%;
`;

export const TextPost = styled.div`
  width: 100%;
  height: fit-content;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1%;

  @media only screen and (max-width: 1366px) {
    padding: 2%;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
  display: flex;
  color: var(--gray-800);
  margin-bottom: 5px;

  h1 {
    font-size: 1rem;
    text-transform: capitalize;
  }

  @media only screen and (max-width: 1366px) {
    h1 {
      font-size: 0.9rem;
    }
  }
`;

export const Description = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  white-space: pre-wrap;

  p {
    text-align: justify;
    font-size: 0.9rem;
    margin-right: 15px;
    color: var(--gray-800);
    white-space: pre-wrap;
  }
`;

export const CommentsContainer = styled.div`
  width: 100%;
  max-height: 55%;
  display: flex;
  flex-direction: column;
  padding: 1%;
`;

export const NewCommentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #cccccc47;
  width: 100%;
  height: 10%;
  display: flex;
`;

export const TextAreaInput = styled.textarea`
  justify-content: center;
  padding: 3%;
  width: 85%;
  height: 100%;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  color: var(--greey-500);

  :disabled {
    background: #cccccc85;
    cursor: no-drop;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
    padding: 2%;
  }

  @media only screen and (max-width: 578px) {
    width: 80%;
  }
`;

export const ButtonNewCommentSave = styled.button`
  width: 15%;
  height: 100%;
  font-weight: 600;
  color: white;
  font-size: 0.9rem;
  background: var(--gray-900);

  :disabled {
    background: var(--gray-800);
    cursor: no-drop;
  }

  @media only screen and (max-width: 578px) {
    width: 20%;
  }
`;

export const ImagesContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5%;

  @media only screen and (max-width: 1366px) {
    height: 8%;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25%;
  height: 100%;

  span {
    font-weight: 600;
    font-size: 1rem;
    margin-left: 2px;
    color: var(--gray-500);
  }

  @media only screen and (max-width: 1366px) {
    width: 30%;

    span {
      font-size: 0.9rem;
    }
  }
`;

export const ActionsAdm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10%;
  height: 100%;
`;

const ActionsCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
`;

export const LikeContainet = styled.button`
  ${ActionsCSS}
`;

export const DislikeContainer = styled.button`
  ${ActionsCSS}
`;

export const CommentContainer = styled.button`
  ${ActionsCSS}
`;

const IconCSS = css`
  width: 1.3rem;
  height: 1.3rem;
  fill: var(--gray-400);
  transition: ease 0.2s;
  cursor: pointer;

  @media only screen and (max-width: 1366px) {
    width: 1.1rem;
    height: 1.1rem;
  }
`;

interface ILike {
  hasClicked: boolean;
}

export const LikeIcon = styled(Like)<ILike>`
  ${IconCSS}
  :hover {
    fill: var(--blue-400);
  }
  ${({ hasClicked }) =>
    hasClicked &&
    css`
      fill: var(--blue-400);
    `}
`;

export const DislikeIcon = styled(Dislike)<ILike>`
  ${IconCSS}

  :hover {
    fill: var(--blue-400);
  }

  ${({ hasClicked }) =>
    hasClicked &&
    css`
      fill: var(--blue-400);
    `}
`;

export const CommentIcon = styled(Comment)`
  ${IconCSS}
`;

export const RemoveIcon = styled(TrashAlt)`
  ${IconCSS}
`;

export const EditIcon = styled(EditAlt)`
  ${IconCSS}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
