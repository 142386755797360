import React from "react";
import { verifyPermission } from "../../services/auth";

import { CreateTutorialDto } from "../../models/dtos/createTutorial";
import ButtonAddNewTutorial from "../ButtonAddNewTutorial";

import { Container, InfoContainer } from "./styles";

interface IProp {
  handleAdd: (
    tutorial: CreateTutorialDto
  ) => Promise<{ success: boolean; error: string }>;
}

const NoDataInfo: React.FC<IProp> = ({ handleAdd }) => {
  return (
    <Container>
      <InfoContainer id="noData-container">
        <h1>Espaço vazio por aqui</h1>
        {verifyPermission("tutorial.add_new") && (
          <ButtonAddNewTutorial handleAdd={handleAdd} />
        )}
      </InfoContainer>
    </Container>
  );
};

export default NoDataInfo;
