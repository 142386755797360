import React, { useState, useEffect } from "react";
import BlogIcon from "../../assets/image/blog.png";
import NoDataImg from "../../assets/svg/nodataTutorial.svg";

import InputSearch from "../../components/InputSearch";
import BlogList from "../../containers/BlogList";
import ModalNewBlog from "../../components/ModalNewBlog";

import PageContainer from "../../containers/PageContainer";
import { Blog as BlogModel } from "../../models/entities/Blog/blog";
import api from "../../services/api";

import {
  Container,
  InputContainer,
  ButtoNewPost,
  Content,
  NoData,
  AddIcon,
} from "./styles";
import swal from "sweetalert";
import Spinner from "../../components/Spinner";

const Blog: React.FC = () => {
  const [filter, setFilter] = useState<string | undefined>();
  const [isVisible, setIsVisible] = useState(false);
  const [blogs, setBlogs] = useState<BlogModel[]>([]);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const openModal = () => {
    setIsVisible((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get("/blog");
        setBlogs(content);
        setShouldSearch(false);
      } catch {
        swal("", "Falha ao carregar registros", "error");
      } finally {
        setLoading(false);
      }
    };
    if (shouldSearch) {
      fetchData();
    }
  }, [shouldSearch]);

  const handleFilter = (pattern?: string): BlogModel[] => {
    if (pattern) {
      return blogs.filter(function (blog) {
        return blog.title.toLowerCase().includes(pattern.toLowerCase());
      });
    }
    return blogs;
  };

  return (
    <Container>
      <PageContainer
        icon={BlogIcon}
        title="Blog"
        description="Aqui você vai encontrar todas as novidades do marketing e acompanhar nossas métricas de tráfego."
      />

      <InputContainer>
        <InputSearch value={filter} setValue={setFilter} />
        <ButtoNewPost id="newPostBlog-button" onClick={openModal}>
          <span>Fazer nova postagem</span>
          <AddIcon />
        </ButtoNewPost>
      </InputContainer>

      {isVisible && (
        <ModalNewBlog
          setIsVisible={setIsVisible}
          setShouldSearch={setShouldSearch}
        />
      )}

      <Content>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {blogs.length ? (
              <BlogList
                setShouldSearch={setShouldSearch}
                blogs={handleFilter(filter)}
              />
            ) : (
              <NoData src={NoDataImg} />
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Blog;
