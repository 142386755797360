import React, { useState } from "react";
import axios from "axios";
import { getToken } from "../../services/auth";
import api from "../../services/api";

import { verifyPermission } from "../../services/auth";
import { Material } from "../../models/entities/Material/material";

import Spinner from "../Spinner";
import ModalPostsInfo from "../ModalPostsInfo";

import pdfImage from "../../assets/svg/pdfIcon.svg";

import {
  Container,
  TopContainer,
  Title,
  Image,
  BottomContainer,
  DownloadIcon,
  ActionsButton,
  RemoveIcon,
  InfoIcon,
  Video,
} from "./styles";

interface IProps {
  index: number;
  material: Material;
  handleDelete: (id: number) => Promise<void>;
}
const CardImages: React.FC<IProps> = ({ index, material, handleDelete }) => {
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [percent, setPercent] = useState(0);

  const openModal = () => {
    setIsVisible((prev) => !prev);
  };

  const handleRemove = async (id: number): Promise<void> => {
    setIsDownloading(true);
    setLoading(true);
    await handleDelete(id);
    setLoading(false);
    setIsDownloading(false);
  };

  const handleDownload = async (material: Material): Promise<void> => {
    setIsDownloading(true);
    setPercent(0);
    const { data } = await api.get(`/s3-upload/find/${material.key_s3}`);

    const { data: blob } = await axios({
      method: "get",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      url: `data:${data.contentType};base64,${data.base64}`,

      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setPercent(percentage);
      },
    });

    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement("a");
    link.href = url;

    link.setAttribute(
      "download",
      `${material.title}.${material?.file_type.split("/")[1]}`
    );

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <Container id={`card-${index}-container`}>
      <TopContainer onClick={openModal}>
        {material?.file_type.includes("pdf") ? (
          <Image
            id={`cardPdf-${index}-container`}
            src={pdfImage}
            alt={material.title}
          />
        ) : (
          <>
            {material?.file_type.includes("video/mp4") ? (
              <Video
                id={`cardVideo-${index}-container`}
                src={material.url}
                controls
              />
            ) : (
              <Image
                id={`cardImage-${index}-container`}
                src={material.url}
                alt={material.title}
              />
            )}
          </>
        )}
        <InfoIcon />
      </TopContainer>

      <BottomContainer>
        <Title id={`cardTitle-${index}-container`}>
          <p>{material.title}</p>
        </Title>
        <ActionsButton>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {isDownloading ? (
                <div>{percent.toFixed(0)}%</div>
              ) : (
                <>
                  {verifyPermission("material.delete_material") && (
                    <RemoveIcon
                      id={`remove-${index}-button`}
                      onClick={() => handleRemove(material.id)}
                    />
                  )}
                  <DownloadIcon
                    id={`download-${index}-button`}
                    onClick={() => handleDownload(material)}
                  />
                </>
              )}
            </>
          )}
        </ActionsButton>
      </BottomContainer>

      <ModalPostsInfo
        index={index}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        material={material}
      />
    </Container>
  );
};

export default CardImages;
