import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 13%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 60%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 87%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const NavBarContainer = styled.div`
  display: flex;
  background: var(--white-200);
  width: 100%;
  height: 6%;
  @media only screen and (max-width: 578px) {
    height: 8%;
  }
`;

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white-50);
  width: 100%;
  height: 94%;
  overflow-y: scroll;

  @media only screen and (max-width: 578px) {
    height: 92%;
  }
`;
