import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
  overflow: hidden;
`;

export const ContentPage = styled.div`
    margin-top: 1.5rem;
    height: 95%;
    iframe {
        width: 100%;
        height: 100%;
    }
`;