import React, { useState, Dispatch, SetStateAction } from "react";
import { verifyPermission } from "../../services/auth";

import { Card } from "../../models/entities/Card/card";
import { decodeToken } from "../../services/auth";
import CommentsList from "../CommentsList";
import ModalNewTutorial from "../ModalNewTutorial";
import PdfIcon from "../../assets/svg/pdfIcon.svg";
import Spinner from "../Spinner";

import swal from "sweetalert";

import {
  Container,
  IndexOf,
  VideoContent,
  VideoInfos,
  Title,
  Description,
  Actions,
  LikeIcon,
  CommentIcon,
  LikeContainet,
  CommentContainer,
  DislikeContainer,
  DislikeIcon,
  ActionsContainer,
  Content,
  ActionsAdm,
  RemoveIcon,
  EditIcon,
  Dropdown,
  Image,
} from "./styles";

interface IProps {
  card: Card;
  position?: number;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  handleAddComment: (
    comment: string,
    parent_id
  ) => Promise<{ success: boolean; error: string }>;
  handleDeleteComment: (
    id: number
  ) => Promise<{ success: boolean; error: string }>;
  handleDelete: (id: number) => Promise<{ success: boolean; error: string }>;
  handleUpdate: (
    tutorial_id: number,
    payload: any
  ) => Promise<{ success: boolean; error: string }>;
  handleLike: (payload: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }) => Promise<{ success: boolean; error: string }>;
}

const CardTutorial: React.FC<IProps> = ({
  card,
  setShouldSearch,
  position,
  handleAddComment,
  handleDeleteComment,
  handleUpdate,
  handleLike,
  handleDelete,
}) => {
  const [visible, setVisible] = useState(false);
  const [liking, setLiking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);

  const handleOpenMenu = (id: number | null): void => {
    setOpenedMenu(id);
  };

  const onDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      swal({
        title: "Exclusão",
        text: "Você tem certeza de que quer excluir?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await handleDelete(id);
          swal("Excluído com sucesso!", {
            icon: "success",
          });
        }
        setShouldSearch(true);
        setLoading(true);
      });
      return { success: true, error: "" };
    } catch (err: any) {
      setLoading(false);
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const onUpdate = async (
    payload: any
  ): Promise<{ success: boolean; error: string }> => {
    try {
      setLoading(true);
      await handleUpdate(card.id, payload);
      swal("Tutorial editado com sucesso!", "", {
        icon: "success",
      });
      setShouldSearch(true);
      return { success: true, error: "" };
    } catch (err: any) {
      setLoading(false);
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";

      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const onLike = async ({
    like,
    deslike,
  }: {
    like: boolean;
    deslike: boolean;
  }): Promise<void> => {
    try {
      setLiking(true);
      await handleLike({ parent_id: card.id, like, deslike });
      setShouldSearch(true);
    } catch (error) {
      console.log(error);
      setLiking(false);
    }
  };

  const isSameId = (id: number): boolean => {
    return openedMenu === id;
  };

  const handleLikeOrDeslike = (prop: string): boolean => {
    const user_id = decodeToken().id;
    const hasLike = card.likes.find((cardLike) => cardLike.user_id === user_id);
    if (!hasLike) {
      return false;
    }
    return hasLike[prop];
  };

  return (
    <>
      <Container key={card.id}>
        <Content>
          <IndexOf>{position}</IndexOf>
          <VideoContent id={`video-${position}-content`}>
            {card.file_type.includes("video") ? (
              !card.file_url.includes("loom") ? (
                <iframe
                  allowFullScreen
                  src={`https://www.youtube.com/embed/${card.file_url}`}
                  title="YouTube video player"
                  width="100%"
                  height="100%"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              ) : (
                <iframe
                  allowFullScreen
                  src={`https://www.loom.com/embed/${
                    card.file_url.split("/share/")[1].split("?")[0]
                  }`}
                  title="Loom video player"
                  width="100%"
                  height="100%"
                />
              )
            ) : card.file_type.includes("pdf") ? (
              <Image src={PdfIcon} />
            ) : (
              <Image src={card.file_url} />
            )}
          </VideoContent>

          <VideoInfos>
            <Title id={`title-${position}-container`}>
              <h1>{card.title}</h1>
            </Title>
            <Description id={`description-${position}-container`}>
              <p>{card.description}</p>
            </Description>

            <ActionsContainer>
              <ActionsAdm>
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    {verifyPermission("tutorial.edit_and_delete") && (
                      <>
                        <RemoveIcon
                          id={`remove-${position}-button`}
                          onClick={() => onDelete(card.id)}
                        />
                        <EditIcon
                          id={`edit-${position}-button`}
                          onClick={() => setVisible(true)}
                        />{" "}
                      </>
                    )}
                  </>
                )}
              </ActionsAdm>

              <Actions>
                {liking ? (
                  <Spinner />
                ) : (
                  <>
                    <LikeContainet id={`like-${position}-button`}>
                      <LikeIcon
                        hasClicked={handleLikeOrDeslike("like")}
                        onClick={() => onLike({ like: true, deslike: false })}
                      />
                      <span>{card.like}</span>
                    </LikeContainet>
                    <DislikeContainer id={`dislike-${position}-button`}>
                      <DislikeIcon
                        hasClicked={handleLikeOrDeslike("deslike")}
                        onClick={() => onLike({ like: false, deslike: true })}
                      />
                      <span>{card.deslike}</span>
                    </DislikeContainer>
                  </>
                )}

                <CommentContainer
                  id={`comment-${position}-button`}
                  onClick={() =>
                    handleOpenMenu(isSameId(card.id) ? null : card.id)
                  }
                >
                  <CommentIcon />
                  <span>{card.comments.length}</span>
                </CommentContainer>
              </Actions>
            </ActionsContainer>
          </VideoInfos>
        </Content>
      </Container>

      {openedMenu && (
        <Dropdown>
          <CommentsList
            comments={card.comments}
            handleAdd={handleAddComment}
            handleDelete={handleDeleteComment}
            parent_id={card.id}
            setShouldSearch={setShouldSearch}
          />
        </Dropdown>
      )}
      <ModalNewTutorial
        card={card}
        isVisible={visible}
        setIsVisible={setVisible}
        handleAdd={onUpdate}
      />
    </>
  );
};

export default CardTutorial;
