import styled from "styled-components";

import { Add } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
  height: 4%;

  @media only screen and (width: 1360px) {
    input {
      height: 2rem;
    }
  }

  @media only screen and (max-width: 578px) {
    padding: 2%;
  }
`;

export const ButtoNewPost = styled.button`
  background: var(--yellow-500);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 1%;

  :hover {
    background: var(--yellow-550);
  }

  @media only screen and (width: 1360px) {
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 578px) {
    border-radius: 50%;
    span {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin-top: 0.8rem;
  overflow-y: scroll;
`;

export const NoData = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AddIcon = styled(Add)`
  width: 25px;
  height: 25px;
  display: none;

  @media only screen and (max-width: 578px) {
    display: flex;
  }
`;
