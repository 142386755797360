import React, { Dispatch, SetStateAction } from "react";
import api from "../../services/api";
import CardTutorial from "../../components/CardTutorial";
import { Tutorial } from "../../models/entities/Tutorial/tutorial";
import swal from "sweetalert";
import { Container } from "./styles";

interface IProps {
  tutorials: Tutorial[];
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const TutorialList: React.FC<IProps> = ({ tutorials, setShouldSearch }) => {
  const handleAddComment = async (
    comment: string,
    tutorial_id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/tutorialComment", {
        tutorial_id,
        comment,
      });
      setShouldSearch(true);
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar comentário";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDeleteComment = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/tutorialComment/${id}`);
      swal("Comentário excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao excluír comentário";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/tutorial/${id}`);
      swal("Tutorial excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao excluir tutorial";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleUpdate = async (
    tutorial_id: number,
    data: any
  ): Promise<{ success: boolean; error: string }> => {
    const payload = {
      title: data.title,
      type: data.type,
      file_url: data.file_url,
      description: data.description,
      file_type: "video",
      s3_key: null,
    };
    try {
      if (data.image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", data.image);

        const {
          data: { location, key, type: file_type },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.file_url = location;
        payload.file_type = file_type;
        payload.s3_key = key;
      }
      await api.put(`/tutorial/${tutorial_id}`, payload);
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao editar tutorial";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleLike = async ({
    parent_id,
    like,
    deslike,
  }: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/tutorialLike", {
        tutorial_id: parent_id,
        like,
        deslike,
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao registrar like";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  return (
    <Container>
      {tutorials
        .map((tutorial) => ({
          id: tutorial.id,
          type: tutorial.type,
          title: tutorial.title,
          file_url: tutorial.file_url,
          file_type: tutorial.file_type,
          description: tutorial.description,
          like: tutorial.like,
          deslike: tutorial.deslike,
          created_at: tutorial.created_at,
          updated_at: tutorial.updated_at,
          deleted_at: tutorial.deleted_at,
          comments: tutorial.tutorialComments.map((tutorialComment) => ({
            id: tutorialComment.id,
            parent_id: tutorialComment.tutorial_id,
            user_id: tutorialComment.user_id,
            user_name: tutorialComment.user_name,
            comment: tutorialComment.comment,
            created_at: tutorialComment.created_at,
          })),
          likes: tutorial.tutorialLikes.map((tutorialLike) => ({
            id: tutorialLike.id,
            like: tutorialLike.like,
            parent_id: tutorialLike.tutorial_id,
            deslike: tutorialLike.deslike,
            user_id: tutorialLike.user_id,
            user_name: tutorialLike.user_name,
            created_at: tutorialLike.created_at,
            updated_at: tutorialLike.updated_at,
          })),
        }))
        .map((card, index) => (
          <CardTutorial
            key={card.id}
            position={index + 1}
            card={card}
            setShouldSearch={setShouldSearch}
            handleAddComment={handleAddComment}
            handleDeleteComment={handleDeleteComment}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            handleLike={handleLike}
          />
        ))}
    </Container>
  );
};

export default TutorialList;
