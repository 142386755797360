import styled from "styled-components";
import { CloseOutline } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.5s 0.5s ease-in-out;
  transition: all 0.5s 0.5s ease-in-out;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
  background: var(--white);
  width: 28rem;
  height: 35rem;
  border-radius: 10px;
  padding: 1%;

  @media only screen and (width: 1440px) {
    height: 30rem;
    width: 25rem;
  }
  @media only screen and (max-width: 1366px) {
    height: 30rem;
    width: 25rem;
  }

  @media only screen and (max-width: 578px) {
    width: 90%;
    height: 35rem;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
  margin-top: 12%;

  @media only screen and (max-width: 578px) {
    margin-top: 17%;
  }
`;

export const Titulo = styled.div`
  display: flex;
  font-weight: 600;
  width: 100%;
  height: 20%;
`;
export const Description = styled.div`
  display: flex;
  overflow-y: scroll;
  width: 100%;
  height: 80%;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
`;

export const Image = styled.img`
  width: 75%;
  height: 100%;
  object-fit: contain;
`;

export const Video = styled.video`
  width: 90%;
  height: 90%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--gray-200);
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70%;
  height: 32px;
  padding: 0;
  z-index: 10;

  @media only screen and (max-width: 578px) {
    left: 5px;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
`;
