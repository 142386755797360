import React, { Dispatch, SetStateAction, useState } from "react";
import api from "../../services/api";
import axios from "axios";
import { getToken } from "../../services/auth";

import CardImages from "../../components/CardImages";
import LoadMore from "../../components/LoadMore";

import { Material } from "../../models/entities/Material/material";

import swal from "sweetalert";
import JSZip from "jszip";

import { Container, DownloadPack } from "./styles";
interface IProps {
  files: Material[];
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const PostMaterialList: React.FC<IProps> = ({ files, setShouldSearch }) => {
  const [loadMoreVisible, setLoadMoreVisible] = useState<number>(5);
  const [isDownloading, setIsDownloading] = useState(false);
  const [percent, setPercent] = useState(0);

  const handleDelete = async (id: number): Promise<void> => {
    try {
      swal({
        title: "Excluir imagem",
        text: "Você tem certeza de que quer excluir esta imagem?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await api.delete(`/material/${id}`);
          swal("Imagem excluída com sucesso!", {
            icon: "success",
          });
        }
        setShouldSearch(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = async (): Promise<void> => {
    setIsDownloading(true);

    if (!files.length) {
      swal("Nenhum meterial para download", "", "warning");
      return;
    }

    try {
      const zip = new JSZip();
      const folder = zip.folder("materiais");

      await Promise.all(
        files
          .filter((file) => file.url)
          .map(async (file) => {
            const { data } = await api.get(`/s3-upload/find/${file.key_s3}`);

            const { data: blob } = await axios({
              method: "get",
              responseType: "blob",
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
              url: `data:${data.contentType};base64,${data.base64}`,

              onDownloadProgress: (progressEvent) => {
                const percentage = Math.round(
                  (progressEvent.loaded * (100 / files.length)) /
                    progressEvent.total
                );

                setPercent((oldPercent) =>
                  oldPercent + percentage > 100 ? 100 : oldPercent + percentage
                );
              },
            });

            folder?.file(
              `${file.id}-material.${blob.type.split("/")[1]}`,
              blob
            );
          })
      );

      zip.generateAsync({ type: "blob" }).then(function (content) {
        const url = window.URL.createObjectURL(new Blob([content]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "materiais.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      console.log("erro");
    }
  };

  return (
    <>
      {files.length && (
        <DownloadPack id="downloadPack-button" onClick={() => handleOk()}>
          <>
            {isDownloading ? (
              <span>{percent.toFixed(0)}%</span>
            ) : (
              <span>Baixar Tudo</span>
            )}
          </>
        </DownloadPack>
      )}
      <Container id="cardList-container">
        {files.slice(0, loadMoreVisible).map((file, mapIndex) => (
          <CardImages
            index={mapIndex + 1}
            key={file.url}
            handleDelete={handleDelete}
            material={file}
          />
        ))}
      </Container>

      {files.length > loadMoreVisible && (
        <LoadMore
          setLoadMoreVisible={setLoadMoreVisible}
          title="Ver Mais Materiais"
        />
      )}
    </>
  );
};

export default PostMaterialList;
