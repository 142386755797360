import styled, { css } from "styled-components";

import {
  HomeAlt,
  CloudDownload,
  Youtube,
  Badge,
  Bookmark,
  Blogger,
  ArrowIosDownwardOutline,
  ArrowIosUpwardOutline,
  CloseOutline,
  Live,
  FilePdf,
  Calendar
} from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--gray-800);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15%;

  @media only screen and (max-width: 578px) {
    margin-bottom: 1rem;
  }
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
  color: var(--gray-400);
  display: none;

  @media only screen and (max-width: 578px) {
    display: flex;
  }
`;

export const LogoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 7.5rem;
  height: 7.5rem;

  @media only screen and (max-width: 1600px) {
    width: 6rem;
    height: 6rem;
  }
  @media only screen and (width: 1440px) {
    width: 5rem;
    height: 5rem;
  }
  @media only screen and (max-width: 1366px) {
    width: 5rem;
    height: 5rem;
  }
`;

export const LabelLogo = styled.img`
  width: 100%;
`;

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  overflow-y: scroll;
`;

interface IMenuCards {
  isActived: boolean;
}

const isolatedMenuHover = css`
  background: var(--yellow-500);
  color: var(--gray-800);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;

  svg {
    fill: var(--gray-800);
  }
`;

export const MenuCards = styled.div<IMenuCards>`
  display: flex;
  align-items: center;
  padding: 0.9rem;
  font-size: 0.8rem;
  color: var(--yellow-500);
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.2s;
  margin-top: 0.2rem;

  @media only screen and (max-width: 1366px) {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 0.7rem;
    padding: 0.7rem;
  }

  svg {
    margin-right: 5px;
  }

  &:hover {
    ${isolatedMenuHover}
  }

  ${({ isActived }) =>
    isActived &&
    css`
      ${isolatedMenuHover}
    `}
`;

export const DropDown = styled.div`
  width: 100%;
  user-select: none;
`;

export const DropDownContent = styled.div`
  width: 100%;
  padding: 0.9rem;
`;

export const MenuItem = styled.div<IMenuCards>`
  padding: 0.9rem;
  color: var(--yellow-opacity);
  border-left: 3px solid var(--yellow-opacity);
  transition: 0.2s;
  font-size: 1rem;

  @media only screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 0.8rem;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    cursor: pointer;
    color: var(--yellow-500);
    background: var(--gray-850);
    border-left: 3px solid var(--yellow-500);
  }

  ${({ isActived }) =>
    isActived &&
    css`
      border-left: 3px solid var(--yellow-500);
      color: var(--yellow-500);
      background: var(--gray-850);
    `}
`;

export const BottomContent = styled.div`
  height: 10%;
`;

const IconCSS = css`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--yellow-500);
`;

export const HomeIcon = styled(HomeAlt)`
  ${IconCSS}
`;
export const DownloadIcon = styled(CloudDownload)`
  ${IconCSS}
`;
export const TutorialIcon = styled(Youtube)`
  ${IconCSS}
`;
export const QuanlityIcon = styled(Badge)`
  ${IconCSS}
`;
export const MarketingIcon = styled(Bookmark)`
  ${IconCSS}
`;
export const BlogIcon = styled(Blogger)`
  ${IconCSS}
`;
export const LiveIcon = styled(Live)`
  ${IconCSS}
`;
export const PdfIcon = styled(FilePdf)`
  ${IconCSS}
`;
export const CalendarIcon = styled(Calendar)`
  ${IconCSS}
`;

export const ArrowDown = styled(ArrowIosDownwardOutline)`
  ${IconCSS}
  margin-left: auto;
`;

export const ArrowUp = styled(ArrowIosUpwardOutline)`
  ${IconCSS}
  margin-left: auto;
`;
