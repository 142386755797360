import styled from "styled-components";
export const Module = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  width: 80%;
  height: 100%;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  width: 100%;
`;

export const TextInfo = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 2rem;

  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 5px;

  span {
    color: var(--yellow-500);
  }

  @media only screen and (max-width: 576px) {
    font-size: 0.9rem;

    h1 {
      font-size: 1.5rem;
    }
  }
`;

export const ModuleContainer = styled.section`
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  background: #fff;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  span {
    color: var(--yellow-500);
  }
  @media only screen and (max-width: 576px) {
    h2 {
      font-size: 1.3rem;
    }
  }
`;

export const ModuleList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  list-style: none;
  padding: 0;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 1rem;
  height: 20rem;
  border: 1px solid var(--gray-200);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.2s;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media only screen and (max-width: 768px) {
    height: 22rem;
  }
`;

export const CardMedia = styled.img`
  width: 100%;
  height: auto;
  max-height: 10rem;
  object-fit: cover;
  border-bottom: 1px solid var(--gray-200);
`;

export const CardContent = styled.div`
  display: flex;
  gap: 5px;
  padding: 1rem;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const CardActions = styled.div`
  padding: 1rem;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardInfo = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }

  @media only screen and (max-width: 576px) {
    h3 {
      font-size: 1rem;
    }
  }
`;

export const Button = styled.button`
  background-color: var(--yellow-500);
  font-weight: bolder;
  padding: 12px;
  border-radius: 4px;
  color: white;
  transition: ease-in-out 0.2s;

  &:hover {
    background-color: #ffa500;
  }
`;
