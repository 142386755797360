import styled from "styled-components";

export const Container = styled.div`
  background: var(--white-100);
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 1%;
  overflow-y: scroll;
`;

export const ContainerNewComment = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
`;

export const AvatarContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;

  @media only screen and (max-width: 578px) {
    width: 20%;
  }
`;

export const Avatar = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
`;

export const NewComment = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Textarea = styled.textarea`
  justify-content: center;
  padding: 1%;
  width: 100%;
  height: 7rem;
  border: 1px solid var(--orange-400);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  background: white;

  @media only screen and (max-width: 578px) {
    height: 5rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
`;

export const ButtonSave = styled.button`
  background: var(--orange-400);
  padding: 1%;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  :hover {
    filter: brightness(90%);
  }

  @media only screen and (max-width: 578px) {
    padding: 2%;
  }
`;
