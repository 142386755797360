import React from "react";

import TablesIcon from "../../assets/svg/tables.svg";
import ManualImg from "../../assets/image/manual.jpeg";
import TabelaImg from "../../assets/image/tabela.jpeg";

import Banner from "../../components/Banner";

import PageContainer from "../../containers/PageContainer";
import { Container, Content } from "./styles";

const TablesAndManual: React.FC = () => {
  return (
    <Container>
      <PageContainer
        icon={TablesIcon}
        title="Tabelas e Manuais"
        description="Para baixar ou visualizar o Manual de Operações e Tabela Nutricional, basta clicar nos botões abaixo."
      />
      <Content>
        <Banner
          type="tableUrl"
          background_url={TabelaImg}
          url="https://upload-service-portal.s3.sa-east-1.amazonaws.com/1d303a74e4e3c13ff8f8a5f91dab71995b0ac117"

        />
        <Banner
          type="manualUrl"
          background_url={ManualImg}
          url="https://upload-service-portal.s3.sa-east-1.amazonaws.com/e5b5727f64dd0dfff9af2c93d9441a94eb61f4e3"
        />
      </Content>
    </Container>
  );
};

export default TablesAndManual;
