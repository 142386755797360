import React, { Dispatch, SetStateAction } from "react";
import { Input } from "./styles";

interface IProps {
  value?: string;
  setValue?: Dispatch<SetStateAction<string | undefined>>;
}

const InputSearch: React.FC<IProps> = ({ value, setValue }) => {
  const handleChange = ({ target: { value } }): void => {
    setValue && setValue(value);
  };
  return (
    <Input
      id="search-input"
      type="text"
      placeholder="Filtre digitando aqui"
      value={value}
      onChange={handleChange}
    />
  );
};

export default InputSearch;
