import React, { Dispatch, SetStateAction, useState } from "react";
import ModalUploadImage from "../ModalUploadImage";
import { Button, FileIcon } from "./styles";
import { MaterialCategory } from "../../models/entities/Material/materialCategory";
interface IProps {
  material_type: number;
  categories: MaterialCategory[];

  handleAdd: (
    title: string,
    description: string,
    image: File,
    category: number
  ) => Promise<void>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ButtonNewUpload: React.FC<IProps> = ({
  handleAdd,
  categories,
  material_type,
  setShouldSearch,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const openModal = () => {
    setIsVisible((prev) => !prev);
  };
  return (
    <>
      <Button id="newUpload-button" onClick={openModal}>
        {" "}
        <FileIcon />
        <span>Novo Upload</span>
      </Button>
      {isVisible && (
        <ModalUploadImage
          setIsVisible={setIsVisible}
          handleAdd={handleAdd}
          categories={categories}
          material_type={material_type}
          setShouldSearch={setShouldSearch}
        />
      )}
    </>
  );
};

export default ButtonNewUpload;
