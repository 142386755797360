import styled, { css } from "styled-components";

import { Download, TrashAlt, InfoCircle } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  height: 320px;

  @media only screen and (max-max-width: 1600px) {
    height: 280px;
  }
  @media only screen and (max-width: 1366px) {
    height: 230px;
  }
  @media only screen and (max-width: 1280px) {
    height: 250px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  position: relative;

  svg {
    display: none;
  }

  :hover {
    opacity: 0.5;
    cursor: pointer;

    svg {
      display: flex;
    }
  }
`;

export const Image = styled.img`
  width: 90%;
  height: 90%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
`;

export const Video = styled.video`
  width: 90%;
  height: 90%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 20%;

  @media only screen and (max-width: 1600px) {
    padding: 3%;
  }
  @media only screen and (width: 1440px) {
    padding: 3%;
  }

  @media only screen and (max-width: 1366px) {
    padding: 3%;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 70%;

  p {
    font-size: 1rem;
    text-overflow: ellipsis;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--gray-900);

    @media only screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 0.8rem;
    }
    @media only screen and (width: 1440px) {
      font-size: 0.8rem;
    }
  }
`;

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  height: 100%;
  justify-content: space-evenly;

  @media only screen and (max-width: 1680px) {
    width: 40%;
  }
  @media only screen and (max-width: 578px) {
    width: 50%;
  }

  @media only screen and (max-width: 1366px) {
    width: 50%;
  }
`;

const IconCSS = css`
  width: 1.2rem;
  height: 1.2rem;
  fill: var(--gray-900);
  cursor: pointer;
  :hover {
    fill: var(--gray-600);
  }
`;

export const DownloadIcon = styled(Download)`
  ${IconCSS}
`;

export const RemoveIcon = styled(TrashAlt)`
  ${IconCSS}
`;

export const InfoIcon = styled(InfoCircle)`
  width: 5rem;
  height: 5rem;
  fill: white;
  position: absolute;
`;
