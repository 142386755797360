import React from 'react'
import { Container, ContentPage } from './styles'

const Calendar = () => {
    return (
        <Container>
            <ContentPage>
            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ecc94b&ctz=America%2FSao_Paulo&hl=pt_BR&title=Calend%C3%A1rio%20do%20Franqueado&showNav=1&showPrint=0&showTabs=0&showCalendars=1&src=Y2FsZW5kYXJpb0B0aGViZXN0YWNhaS5jb20uYnI&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=cHQtYnIuYnJhemlsaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679&color=%230B8043" />
            </ContentPage>
        </Container >
    )
}

export default Calendar