import styled, { css } from "styled-components";
import { Comment, Like, Dislike, TrashAlt, EditAlt } from "../../styles/Icons";

export const Container = styled.div`
  width: 100%;
  min-height: 310px;
  max-height: 310px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  background: var(--white);
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-max-width: 1600px) {
    height: 250px;
  }

  @media only screen and (max-width: 1440px) {
    height: 250px;
  }
  @media only screen and (max-width: 1366px) {
    height: 200px;
  }

  @media only screen and (max-width: 578px) {
    height: min-content;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const VideoContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  padding: 1%;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const VideoInfos = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--gray-600);

  @media only screen and (max-width: 578px) {
    width: 100%;
    padding: 1%;
  }
`;

export const IndexOf = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  border-radius: 16px;
  background: var(--yellow-500);
  font-weight: 600;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;

  @media only screen and (max-width: 578px) {
    display: none;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20%;
  display: flex;
  color: var(--gray-900);

  h1 {
    font-size: 1.2rem;
    text-transform: capitalize;

    @media only screen and (max-width: 1600px) {
      font-size: 1rem;
    }

    @media only screen and (max-width: 1366px) {
      font-size: 1rem;
    }
    @media only screen and (width: 1440px) {
      font-size: 1rem;
    }

    @media only screen and (width: 1280px) {
      font-size: 1rem;
    }
  }
`;

export const Description = styled.div`
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: flex-start;
  overflow-y: scroll;

  @media only screen and (max-width: 578px) {
    overflow-y: visible;
    height: 100%;
  }

  p {
    text-align: justify;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 15px;
    color: var(--gray-900);
    white-space: pre-wrap;

    @media only screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 0.8rem;
    }
    @media only screen and (width: 1440px) {
      font-size: 0.9rem;
    }

    @media only screen and (width: 1280px) {
      font-size: 0.85rem;
    }

    @media only screen and (max-width: 578px) {
      margin-right: 0;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 15%;

  @media only screen and (max-width: 578px) {
    height: 20%;
    padding: 1% 0 1% 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 40%;
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    margin-left: 2px;
    color: var(--gray-500);

    @media only screen and (max-width: 1600px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 0.8rem;
    }
    @media only screen and (width: 1440px) {
      font-size: 0.8rem;
    }
  }
`;

export const ActionsAdm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 20%;
  }
`;

const ActionsCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
`;

export const LikeContainet = styled.button`
  ${ActionsCSS}
`;

export const DislikeContainer = styled.button`
  ${ActionsCSS}
`;

export const CommentContainer = styled.button`
  ${ActionsCSS}
`;

const IconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--gray-400);
  transition: ease-in-out 0.2s;
  cursor: pointer;

  @media only screen and (max-width: 1600px) {
    width: 1.25rem;
    height: 1.25rem;
  }
  @media only screen and (max-width: 1366px) {
    width: 1.25rem;
    height: 1.25rem;
  }
  @media only screen and (width: 1440px) {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    fill: var(--yellow-500);
  }
`;

interface ILike {
  hasClicked: boolean;
}

export const LikeIcon = styled(Like)<ILike>`
  ${IconCSS}
  :hover {
    fill: var(--blue-400);
  }
  ${({ hasClicked }) =>
    hasClicked &&
    css`
      fill: var(--blue-400);
    `}
`;

export const DislikeIcon = styled(Dislike)<ILike>`
  ${IconCSS}

  :hover {
    fill: red;
  }

  ${({ hasClicked }) =>
    hasClicked &&
    css`
      fill: red;
    `}
`;

export const CommentIcon = styled(Comment)`
  ${IconCSS}
`;

export const RemoveIcon = styled(TrashAlt)`
  ${IconCSS}
`;

export const EditIcon = styled(EditAlt)`
  ${IconCSS}
`;

export const Dropdown = styled.div`
  z-index: 100;
  width: 100%;
  height: max-content;
  top: 47%;
  margin-bottom: 10px;
`;

interface IImage {
  src?: string;
}
export const Image = styled.div<IImage>`
  width: 100%;
  height: 100%;
  ${({ src }) => css`
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;
