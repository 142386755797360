import React, { useState, useEffect } from "react";

import SideMenu from "../../components/SideMenu";
import TopNavBar from "../../components/TopNavBar";
import { useWindowSize } from "../../hook/useWindowsSize";

import {
  Container,
  MenuContainer,
  PageContainer,
  Page,
  NavBarContainer,
} from "./styles";

const Layout: React.FC = ({ children }) => {
  const size = useWindowSize();

  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    setIsVisible(size.width > 575 ? true : false);
  }, [size.width]);

  return (
    <Container>
      {isVisible && (
        <MenuContainer>
          <SideMenu setIsVisible={setIsVisible} />
        </MenuContainer>
      )}
      <PageContainer>
        <NavBarContainer>
          <TopNavBar setIsVisible={setIsVisible} />
        </NavBarContainer>
        <Page>{children}</Page>
      </PageContainer>
    </Container>
  );
};

export default Layout;
