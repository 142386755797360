import styled from "styled-components";

import { CloseOutline } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.5s 0.5s ease-in-out;
  transition: all 0.5s 0.5s ease-in-out;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
  background: var(--white);
  width: 28rem;
  height: fit-content;
  border-radius: 10px;

  @media only screen and (width: 1440px) {
    height: 30rem;
    width: 25rem;
  }
  @media only screen and (max-width: 1366px) {
    height: 30rem;
    width: 25rem;
  }

  @media only screen and (max-width: 578px) {
    height: 30rem;
    width: 90%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 75%;
  margin-top: 12%;
  padding: 0% 4% 0% 4%;
  gap: 1.2rem;

  span {
    font-size: 1rem;
    font-weight: 600;
    color: var(--gray-500);
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 578px) {
    margin-top: 15%;
  }
`;

export const TitleContet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;

  span {
    font-size: 1rem;
  }
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25%;
`;
export const UrlContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 45%;
  margin-top: 10px;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--gray-200);
  position: absolute;
  top: 10px;
  left: 20px;
  width: 70%;
  height: 32px;
  padding: 0;
  z-index: 10;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const Input = styled.input`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 2.5rem;
  border: 1px solid #cecece;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  transition: ease-in-out 0.2s;

  &:disabled {
    background-color: var(--gray-200);
    color: var(--gray-500);
    cursor: not-allowed;
  }

  &:focus,
  &:hover {
    border: 1px solid var(--gray-500);
  }
`;

export const Textarea = styled.textarea`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 6rem;
  border: 1px solid #cecece;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
  transition: ease-in-out 0.2s;

  &:focus,
  &:hover {
    border: 1px solid var(--gray-500);
  }
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 2%;
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
`;
