import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Card } from "../../models/entities/Card/card";

import ButtonSave from "../ButtonSave";
import Spinner from "../Spinner";
import Upload from "../Upload";

import swal from "sweetalert";

import {
  Container,
  Content,
  CloseIcon,
  TitleModal,
  TitleContainer,
  SaveButtonContainer,
  Input,
  DescriptionContent,
  TitleContet,
  UrlContent,
  Textarea,
  UploadContainer,
} from "./styles";
import { TutorialType } from "../../models/enums/tutorialType";

interface IProp {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  handleAdd: (payload: any) => Promise<{ success: boolean; error: string }>;
  card?: Card;
}

const ModalNewTutorial: React.FC<IProp> = ({
  isVisible,
  setIsVisible,
  handleAdd,
  card,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState<string | undefined>(card?.title);
  const [description, setDescription] = useState<string | undefined>(
    card?.description
  );
  const [video_url, setUrl] = useState<string | undefined>(card?.file_url);
  const [urlType, setUrlType] = useState("");
  const modalRef = useRef(null);
  const { type } = useParams<{ type: string }>();

  const resetModal = () => {
    setTitle("");
    setDescription("");
    setUrl("");
    setImage(null);
    setUrlType("");
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsVisible(false);
      resetModal();
    }
  };

  const handleUrlChange = ({ target: { value } }) => {
    setUrl(value);
    setImage(null);
    // Verifique se o URL é do Loom ou do YouTube
    if (value.includes("loom.com")) {
      setUrlType("loom");
    } else if (value.includes("youtu.be") || value.includes("youtube.com")) {
      setUrlType("youtube");
    } else {
      setUrlType("");
    }
  };

  const handleSave = async () => {
    try {
      if (loading || !title || !description) {
        return swal("Oops!", "Preencha os campos corretamente", "warning");
      }
      setLoading(true);
      let file_url = video_url;
      if (urlType === "youtube") {
        file_url = video_url?.split("https://youtu.be/")[1];
      }

      const { success, error } = await handleAdd({
        title,
        description,
        file_url,
        image,
      });
      if (success) {
        swal("Cadastrado com sucesso!", "", "success");
        setIsVisible(false);
        setLoading(false);
      } else {
        swal("Oops!", error, "error");
        setIsVisible(true);
        setLoading(false);
        console.log(error);
      }

      setIsVisible(false);
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";
      swal("Oops!", error, "error");
    }
  };

  const handleUpload = async (files: File[]): Promise<void> => {
    setUrl(undefined);
    setImage(files[0]);
  };

  const maxSize = 15 * 1024 * 1024;

  return (
    <>
      {isVisible ? (
        <Container ref={modalRef} onClick={closeModal}>
          <Content>
            <TitleModal>
              <span>
                Adicionar{" "}
                {type === TutorialType.LIVE_FRANQUEADO.toString()
                  ? "Live"
                  : "Tutorial"}
              </span>
            </TitleModal>

            <TitleContainer>
              <TitleContet>
                <span>Título</span>
                <Input
                  id="title-input"
                  defaultValue={card?.title}
                  placeholder={`Digite o titulo ${
                    type === TutorialType.LIVE_FRANQUEADO.toString()
                      ? "da live"
                      : "do tutorial"
                  }`}
                  onChange={({ target: { value } }) =>
                    setTitle(value.toLowerCase())
                  }
                />
              </TitleContet>

              <DescriptionContent>
                <span>Descrição</span>
                <Textarea
                  id="description-textarea"
                  defaultValue={card?.description}
                  placeholder={`Informe a descrição ${
                    type === TutorialType.LIVE_FRANQUEADO.toString()
                      ? "da live"
                      : "do tutorial"
                  }`}
                  onChange={({ target: { value } }) => setDescription(value)}
                />
              </DescriptionContent>

              <UrlContent>
                <span>URL do Vídeo</span>
                <Input
                  id="url-input"
                  defaultValue={card?.file_url}
                  placeholder="Informe a URL do vídeo"
                  onChange={handleUrlChange}
                  disabled={!!image && urlType !== "loom"}
                />
              </UrlContent>

              {type !== TutorialType.LIVE_FRANQUEADO.toString() && (
                <UploadContainer>
                  <Upload
                    onUpload={handleUpload}
                    image={image}
                    setImage={setImage}
                    disabled={!!video_url}
                    typeFile={"image/*,application/pdf"}
                    maxSize={maxSize}
                  />
                  <span>Proporção: 1617x747</span>
                </UploadContainer>
              )}
            </TitleContainer>

            <SaveButtonContainer id="saveButton-container">
              {loading ? (
                <Spinner />
              ) : (
                <ButtonSave
                  loading={loading}
                  description="Salvar"
                  onClick={() => handleSave()}
                  disabled={!description && !video_url}
                />
              )}
            </SaveButtonContainer>

            <CloseIcon
              id="close-button"
              aria-label="Close Modal"
              onClick={() => {
                setIsVisible((prev) => !prev);
                resetModal();
              }}
            />
            {children}
          </Content>
        </Container>
      ) : null}
    </>
  );
};

export default ModalNewTutorial;
