import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding: 0.6%;

  @media only screen and (max-width: 578px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1%;
    margin-top: 10px;
  }
`;

export const DownloadPack = styled.button`
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 8px;
  outline: none;
  background: #ed6936;
  width: 10rem;
  height: 2.5rem;
  margin: 10px 0;

  span {
    font-weight: bolder;
    font-size: 0.7rem;
    color: var(--white);
    text-transform: uppercase;
  }
`;
