import styled, { css } from "styled-components";

import { Airplay, ArrowDown, Menu, Notifications } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  color: var(--gray-400);
  text-transform: uppercase;
  width: 20%;
  height: 100%;

  @media only screen and (max-width: 1600px) {
    font-size: 1rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 578px) {
    width: 30%;
    font-size: 0.7rem;
  }
`;

export const Actions = styled.div`
  display: flex;

  width: 25%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 80%;
    justify-content: flex-end;
  }

  @media only screen and (width: 1280px) {
    width: 28%;
  }
`;

export const LogoutButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    display: none;
  }
`;

export const NotificationsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 578px) {
    display: none;
  }
`;

export const Badge = styled.div`
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const IconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--gray-400);

  @media only screen and (max-width: 1600px) {
    width: 1.5rem;
    height: 1.5rem;
  }
  @media only screen and (max-width: 1366px) {
    width: 1.5rem;
    height: 1.5rem;
  }
  @media only screen and (width: 1440px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const LogoutIcon = styled(Airplay)`
  ${IconCSS}
`;

export const NotificationIcon = styled(Notifications)`
  ${IconCSS}
`;

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30%;
  height: 100%;
`;

export const Avatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  @media only screen and (max-width: 1600px) {
    width: 2.2rem;
    height: 2.2rem;
  }
  @media only screen and (width: 1440px) {
    width: 2.2rem;
    height: 2.2rem;
  }
  @media only screen and (max-width: 1366px) {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const MenuIcon = styled(Menu)`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.8rem;
  fill: var(--gray-600);
  display: none;

  @media only screen and (max-width: 578px) {
    display: flex;
  }
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 40%;
  }
`;

export const LabelUser = styled.label`
  font-size: 1rem;
  color: var(--gray-600);
  text-transform: capitalize;

  @media only screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }
`;

export const LabelRole = styled.label`
  font-size: 1rem;
  color: var(--gray-500);

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }
  @media only screen and (width: 1440px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
