import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { getTokenInfo } from "../../services/auth";
import DropdownNotification from "../DropdownNotification";
import {
  Container,
  Title,
  Actions,
  LogoutButton,
  InfoUser,
  AvatarMenu,
  LogoutIcon,
  Avatar,
  LabelUser,
  LabelRole,
  MenuIcon,
  NotificationIcon,
  NotificationsButton,
} from "./styles";

interface IProps {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const TopNavBar: React.FC<IProps> = ({ setIsVisible }) => {
  const [isVisibleNotification, setIsVisibleNotification] = useState(false);
  const openNotification = () => {
    setIsVisibleNotification((prev) => !prev);
  };

  const openMenu = () => {
    setIsVisible((prev) => !prev);
  };

  const modalRef = useRef(null);

  const closeDialog = (e) => {
    if (modalRef.current === e.target) {
      setIsVisibleNotification(false);
    }
  };

  const formatPerfil = (perfil?: number) => {
    if (perfil === 1) {
      return "Master";
    }
    if (perfil === 2) {
      return "Admin";
    }
    return "Usuário";
  };

  return (
    <>
      <Container ref={modalRef} onClick={closeDialog}>
        <MenuIcon onClick={openMenu} />
        <Title>portal do franqueado</Title>
        <Actions>
          <NotificationsButton>
            <NotificationIcon onClick={openNotification} />
          </NotificationsButton>

          <DropdownNotification
            isVisibleNotification={isVisibleNotification}
            setIsVisibleNotification={setIsVisibleNotification}
          />

          <LogoutButton>
            <LogoutIcon />
          </LogoutButton>

          <AvatarMenu>
            <Avatar
              src={
                getTokenInfo()?.image
                  ? getTokenInfo()?.image
                  : "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
              }
            />
          </AvatarMenu>

          <InfoUser>
            <LabelUser>{getTokenInfo()?.name}</LabelUser>
            <LabelRole>{formatPerfil(getTokenInfo()?.level)}</LabelRole>
          </InfoUser>
        </Actions>
      </Container>
    </>
  );
};

export default TopNavBar;
