import React, { useState, useEffect } from "react";
import { verifyPermission } from "../../services/auth";
import api from "../../services/api";
import { Marketing as MarketingModel } from "../../models/entities/Marketing/marketing";

import MarketingIcon from "../../assets/svg/marketing.svg";

import PageContainer from "../../containers/PageContainer";
import MarketingList from "../../containers/MarketingList";

import ButtonAddNewMarketing from "../../components/ButtonAddNewMarketing";
import Spinner from "../../components/Spinner";
import moment from "moment";

import {
  Container,
  TopContent,
  Infos,
  DashAds,
  MiddleBar,
  Content,
  ButtonAds,
  NoData,
  Image,
  Info,
} from "./styles";
import { CreateMarketingDto } from "../../models/dtos/createMarketing";

const Marketing: React.FC = () => {
  const [marketing, setMarketing] = useState<MarketingModel[]>([]);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAdd = async (
    marketing: CreateMarketingDto
  ): Promise<{ success: boolean; error: string }> => {
    let payload = {
      title: marketing.title,
      file_url: marketing.file_url,
      file_type: "video",
      content: marketing.description,
      s3_key: null,
    };
    try {
      if (marketing.image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", marketing.image);

        const {
          data: { location, key, type: file_type },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.file_url = location;
        payload.file_type = file_type;
        payload.s3_key = key;
      }

      await api.post("/marketing", {
        ...payload,
      });
      setShouldSearch(true);
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar novidade";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get(
          `/marketing?dataInicial=${moment(new Date())
            .subtract(1, "year")
            .format("DD/MM/YYYY")}&dataFinal=${moment(new Date())
            .add(10, "years")
            .format("DD/MM/YYYY")}`
        );

        setMarketing(content);
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    }
    if (shouldSearch) {
      fetchData();
    }
  }, [shouldSearch]);

  return (
    <Container>
      <PageContainer
        icon={MarketingIcon}
        title="Marketing/Campanhas/Anúncios"
        description="Aqui você vai encontrar todas as novidades do marketing
          e acompanhar nossas métricas de tráfego."
      />

      <TopContent>
        <Infos>
          <h2>
            <span>Marketing</span> The Best Açaí
          </h2>
          <p>
            Você sabe como funciona o marketing das franquias do The Best?{" "}
            <br />
            <br /> O marketing é um dos principais pilares do The Best Açaí.
            Possuímos uma equipe própria voltada para a{" "}
            <span>publicidade e design</span>. Nossos profissionais são focados
            em representar com excelência a empresa e atender da melhor forma os
            franqueados e clientes. <span>NOSSO OBJETIVO</span> é fazer com que
            os clientes se tornem fãs da marca, dos produtos e serviços
            prestados. Com isso, além de conquistar e fidelizar, eles se tornam
            defensores e divulgadores da marca. Utilizamos um conjunto de
            estratégias que visam a <span>aproximação dos clientes</span>, a
            construção e disseminação de marca, a{" "}
            <span>prospecção, fidelização e criação de autoridade</span> no
            mercado. <br /> <br /> Conhecemos nosso público-alvo, por isso as
            ações que implementamos fazem com que os{" "}
            <span>clientes se sintam valorizados e representados</span> através
            da diversidade de linguagem e pessoas que participam de nossas
            campanhas. Como o The Best Açaí já é sucesso, os clientes utilizam
            os canais de divulgação para falar de maneira positiva dos nossos
            produtos e serviços. Eles se tornaram uma de nossas formas de
            propaganda, estimulando novas pessoas a conhecer e confiar na marca.
          </p>
        </Infos>

        <DashAds>
          <h1>Dashboard de Anúncios</h1>
          <p>
            Você pode acompanhar o investimento <br /> e retorno das suas
            postagens aqui:
          </p>

          <ButtonAds
            id="ads-button"
            href="https://app.powerbi.com/view?r=eyJrIjoiOGQxY2YyNWItM2M0OC00MTI1LWJlNzItMTExZTEzYjg5MGE5IiwidCI6ImJhODVmMDBhLTQwMGUtNDEzNS04ODg1LTU0Mjk3ZDk2MGZiZSJ9"
            target="_blank"
            rel="noopener"
          >
            Dashboard de Anúncios
          </ButtonAds>
        </DashAds>
      </TopContent>

      <MiddleBar>
        <h2>Últimas Novidades</h2>
        {verifyPermission("marketing.add_new") && (
          <ButtonAddNewMarketing handleAdd={handleAdd} />
        )}
      </MiddleBar>

      {loading ? (
        <Spinner />
      ) : (
        <Content>
          {marketing.length ? (
            <MarketingList
              marketing={marketing}
              setShouldSearch={setShouldSearch}
            />
          ) : (
            <NoData>
              <Info>
                <h1>Nenhuma novidade por aqui!</h1>
                <p>
                  Mas não se preocupe, assim que tivermos uma novidade <br />{" "}
                  ela será notificada para você conferir.
                </p>
              </Info>
              <Image />
            </NoData>
          )}
        </Content>
      )}
    </Container>
  );
};

export default Marketing;
