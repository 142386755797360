import { datadogRum } from "@datadog/browser-rum";

const clientToken = process.env.REACT_APP_DD_TOKEN as string;

datadogRum.init({
  applicationId: "f3c0a104-9716-405c-b34a-765fe21c8466",
  clientToken,
  site: "us3.datadoghq.com",
  service: "portal-franchisee",
  env: "prod",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

if (clientToken) datadogRum.startSessionReplayRecording();