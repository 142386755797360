import styled from "styled-components";

import { CloseOutline } from "../../styles/Icons";

export const Container = styled.div`
  position: fixed;
  width: 18%;
  height: 50%;
  top: 6%;
  left: 67%;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
  box-sizing: border-box;
  outline: 0;
  overflow: hidden;
  z-index: 1;

  @media only screen and (max-width: 1280px) {
    width: 20%;
    height: 40%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--gray-200);
  position: absolute;
  top: 10px;
  left: 20px;
  width: 70%;
  height: 32px;
  padding: 0;
  z-index: 10;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  margin-top: 15%;
  padding: 1%;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 15%;
  padding: 1%;
  color: var(--gray-600);

  h2 {
    margin-bottom: 2%;
  }
  p {
    font-size: 0.9rem;
    text-align: center;
  }

  @media only screen and (max-width: 1366px) {
    h2 {
      font-size: 1.1rem;
    }

    p {
      font-size: 0.7rem;
    }
  }
`;

export const Sticker = styled.img`
  width: 50%;
  height: 40%;
  object-fit: cover;
  margin-bottom: 1%;
`;

export const NotificationCard = styled.div`
  display: flex;
  width: 100%;
  height: 25%;
  margin-bottom: 2%;
  padding: 1%;
  border-right: 5px solid orange;
`;

export const ImageNewContainer = styled.div`
  width: 60%;
  height: 100%;
`;
export const ImageNew = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const InfoNewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
  padding: 1%;

  span {
    font-size: 0.9rem;
    text-align: center;
    font-weight: 600;
    color: var(--gray-600);
  }

  p {
    font-size: 0.8rem;
    margin-top: 5px;
    color: var(--gray-500);
  }
`;

export const ButtonView = styled.button`
  background: var(--yellow-500);
  border-radius: 8px;
  padding: 3%;
  color: white;
  margin-top: 10%;
  font-weight: 600;
  :hover {
    background: var(--yellow-550);
  }
`;
