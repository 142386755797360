import React, { useState, Dispatch, SetStateAction } from "react";
import moment from "moment";
import CardComment from "../CardComment";
import Spinner from "../Spinner";

import { CardComment as CardCommentModel } from "../../models/entities/Card/cardComment";
import {
  Container,
  ContainerNewComment,
  AvatarContent,
  NewComment,
  Avatar,
  Textarea,
  ButtonContainer,
  ButtonSave,
} from "./styles";
import swal from "sweetalert";
import { verifyPermission } from "../../services/auth";

interface IProps {
  comments: CardCommentModel[];
  handleAdd: (
    comment: string,
    parent_id
  ) => Promise<{ success: boolean; error: string }>;
  handleDelete: (id: number) => Promise<{ success: boolean; error: string }>;
  parent_id: number;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const CommentsList: React.FC<IProps> = ({
  comments,
  handleAdd,
  handleDelete,
  parent_id,
  setShouldSearch,
}) => {
  const [comment, setComment] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const onCommentAdd = async (): Promise<void> => {
    try {
      if (!comment) {
        return;
      }
      setLoading(true);
      await handleAdd(comment, parent_id);
      setComment(undefined);
      setLoading(false);
      setShouldSearch(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onCommentDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      swal({
        title: "Excluir comentário",
        text: "Você tem certeza de que quer excluir este comentário?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await handleDelete(id);
          swal("Comentário excluído com sucesso!", {
            icon: "success",
          });
        }
        setShouldSearch(true);
        setLoading(true);
      });
      return { success: true, error: "" };
    } catch (err: any) {
      swal("Oops!", " Erro ao excluir comentário", {
        icon: "error",
      });
      setLoading(false);
      const error =
        err.response?.data?.message || "Falha ao realizar registro no blog";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  return (
    <Container>
      {verifyPermission("add_comment") && (
        <ContainerNewComment>
          <AvatarContent>
            <Avatar src="https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png" />
          </AvatarContent>

          <NewComment>
            <Textarea
              id={`newComment-${parent_id}-textarea`}
              placeholder="Deixe seu comentário"
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}
            />
            <ButtonContainer>
              {loading ? (
                <Spinner />
              ) : (
                <ButtonSave
                  id={`buttonSave-${parent_id}-button`}
                  onClick={() => onCommentAdd()}
                >
                  Publicar Comentário
                </ButtonSave>
              )}
            </ButtonContainer>
          </NewComment>
        </ContainerNewComment>
      )}

      {comments.map((comment, indexMap) => (
        <CardComment
          index={indexMap + 1}
          key={comment.id}
          image_avatar={
            "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
          }
          nickname={comment.user_name}
          created_at={moment(comment.created_at).format("DD/MM/YYYY HH:mm:ss")}
          comment={comment.comment}
          handleDelete={onCommentDelete}
          id={comment.id}
          user_id={comment.user_id}
        />
      ))}
    </Container>
  );
};

export default CommentsList;
