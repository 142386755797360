import styled from "styled-components";

import NoDataImage from "../../assets/svg/nodataTutorial.svg";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-image: url(${NoDataImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2%;

  h1 {
    font-size: 35px;
    font-family: "Baloo Tammudu 2";
    color: var(--gray-700);
  }
`;
