import React, { Dispatch, SetStateAction } from "react";

import { Container, LoadMoreButton } from "./styles";

interface IProp {
  title: string;
  setLoadMoreVisible: Dispatch<SetStateAction<number>>;
  defaultQuantity?: number;
}

const LoadMore: React.FC<IProp> = ({
  title,
  setLoadMoreVisible,
  defaultQuantity,
}) => {
  const showMoreItems = () => {
    setLoadMoreVisible((prev) => prev + (defaultQuantity || 5));
  };

  return (
    <Container onClick={showMoreItems}>
      <LoadMoreButton id={`loadMore-${title.replace(/\s/g, "")}-button`}>
        <span>{title}</span>
      </LoadMoreButton>
    </Container>
  );
};

export default LoadMore;
