import React, { Dispatch, SetStateAction } from "react";
import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { MaterialCategory } from "../../models/entities/Material/materialCategory";
import PostMaterialList from "../../containers/PostMaterialList";
import { Material } from "../../models/entities/Material/material";

import swal from "sweetalert";

import {
  Container,
  NameCategory,
  ArrowDownIcon,
  Dropdown,
  ArrowUpIcon,
  DeleteCategory,
} from "./styles";

interface IProps {
  index: number;
  filter: string | undefined;
  category: MaterialCategory;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  visibleCategory: number | null;
  setVisibleCategory: Dispatch<SetStateAction<number | null>>;
}

const CollapseCategoryMaterial: React.FC<IProps> = ({
  index,
  category,
  setShouldSearch,
  filter,
  setVisibleCategory,
  visibleCategory,
}) => {
  const openList = () => {
    setVisibleCategory(category.id === visibleCategory ? null : category.id);
  };

  const handleFilter = (pattern?: string): Material[] => {
    if (pattern) {
      return category.material.filter(function (material) {
        return material.title.toLowerCase().includes(pattern.toLowerCase());
      });
    }
    return category.material;
  };

  const handleDelete = async (): Promise<void> => {
    try {
      swal({
        title: "Excluir categoria",
        text: `Você tem certeza de que quer excluir essa categoria?`,
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await api.delete(`/materialCategory/${category.id}`);
          swal("Categoria excluída com sucesso!", {
            icon: "success",
          });
        }

        setShouldSearch(true);
      });
    } catch (error) {
      console.log(error);
      swal("Oops!", "Erro ao excluir a categoria", {
        icon: "error",
      });
    }
  };

  return (
    <>
      <Container
        id={`category-${index}-container`}
        onClick={openList}
        src={category.url}
      >
        <NameCategory>{category.name}</NameCategory>

        {category.material.length !== 0 && (
          <>
            {visibleCategory === category.id ? (
              <ArrowUpIcon id="upArrow-button" />
            ) : (
              <ArrowDownIcon id="downArrow-button" />
            )}
          </>
        )}

        {category.material.length === 0 &&
          verifyPermission("material.delete_material_category") && (
            <DeleteCategory id="deleteCategory-button" onClick={handleDelete}>
              Excluir
            </DeleteCategory>
          )}
      </Container>

      {visibleCategory === category.id && category.material.length > 0 && (
        <Dropdown>
          <PostMaterialList
            files={handleFilter(filter)}
            setShouldSearch={setShouldSearch}
          />
        </Dropdown>
      )}
    </>
  );
};

export default CollapseCategoryMaterial;
