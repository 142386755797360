import styled from "styled-components";

import { CheckDouble } from "../../styles/Icons";

export const Button = styled.button`
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--yellow-500);
  background: var(--yellow-500);

  padding: 2%;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  transition: all ease-in 0.2s;

  svg {
    fill: var(--white);
  }

  span {
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
  }

  :hover {
    background-color: #ffa500;
  }
`;

export const CheckIcon = styled(CheckDouble)`
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 5px;
  margin-bottom: 1px;
  fill: var(--yellow-500);
`;
