import React, { Dispatch, SetStateAction } from "react";

import UploadSVG from "../../assets/svg/uploadIcon.svg";

import Dropzone from "react-dropzone";

import { DropContainer, UploadMessage, Container, UploadIcon } from "./styles";

interface IProps {
  image: File | null;
  setImage: Dispatch<SetStateAction<File | null>>;
  onUpload: (files: any) => void;
  disabled?: boolean;
  maxFiles?: number;
  typeFile?: string;
  maxSize?: number;
}

const Upload: React.FC<IProps> = ({
  onUpload,
  image,
  disabled,
  maxFiles,
  typeFile,
  maxSize,
}) => {
  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique ou arraste para adicionar o arquivo
          <strong>{image?.name}</strong>
        </UploadMessage>
      );
    }

    if (isDragReject || onUpload.length > (maxFiles || 1)) {
      return (
        <UploadMessage typeof="error">Arquivo não suportado!</UploadMessage>
      );
    }

    return (
      <UploadMessage typeof="success">Adicione o arquivo aqui!</UploadMessage>
    );
  };

  return (
    <Container>
      <Dropzone
        disabled={disabled}
        accept={typeFile}
        onDropAccepted={onUpload}
        maxFiles={maxFiles || 1}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            id="upload-dropzone"
            {...getRootProps()}
            dragActive={isDragActive}
            dragReject={isDragReject}
            disabled={disabled}
          >
            <input {...getInputProps()} />

            <UploadIcon src={UploadSVG} />
            {renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    </Container>
  );
};

export default Upload;
