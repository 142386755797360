import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  background: var(--white);
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 4px;
  padding: 1%;
`;

export const AvatarContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;

  @media only screen and (max-width: 578px) {
    width: 20%;
  }
`;

export const Avatar = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;

  @media only screen and (max-width: 1366px) {
    width: 40px;
    height: 40px;
  }

  @media only screen and (max-width: 578px) {
    height: 50px;
    width: 50px;
  }
`;

export const CommentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 90%;

  span {
    font-size: 12px;
    color: var(--gray-500);
  }

  @media only screen and (max-width: 1366px) {
    span {
      font-size: 0.7rem;
    }
  }
`;

export const Nickname = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--gray-700);
  height: 30%;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;

  span {
    font-size: 0.9rem;
    color: var(--gray-400);
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;

    span {
      font-size: 0.8rem;
    }
  }
`;

export const Comment = styled.div`
  display: flex;
  text-align: justify;
  width: 100%;
  height: fit-content;
  margin-top: 3px;
  font-size: 15px;
  color: var(--gray-600);
`;
