import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  flex-direction: column;

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  margin-top: 10px;
  overflow-y: scroll;
`;
