import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
  height: 5%;

  @media only screen and (max-width: 578px) {
    padding: 1%;
  }
`;

export const Content = styled.div``;
