import styled from "styled-components";

interface IProps {
  background_url: string;
}

export const Container = styled.a<IProps>`
  display: flex;
  width: 85%;
  height: 27%;
  border-radius: 10px;
  margin-bottom: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-image: url(${(props) => props.background_url});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  @media only screen and (max-width: 1680px) {
    height: 23%;
    width: 90%;
  }

  @media only screen and (width: 1280px) {
    height: 23%;
    width: 100%;
  }

  @media only screen and (max-width: 578px) {
    width: 95%;
  }
`;
