import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";

import { verifyPermission } from "../../services/auth";

import NoDataTutorial from "../../components/NoDataTutorial";
import InputSearch from "../../components/InputSearch";
import ButtonAddNewTutorial from "../../components/ButtonAddNewTutorial";

import managementStore from "../../assets/svg/managementStore.svg";
import Investment from "../../assets/svg/investment.svg";
import LiveImg from "../../assets/svg/live.svg";

import PageContainer from "../../containers/PageContainer";
import TutorialList from "../../containers/TutorialList";
import Spinner from "../../components/Spinner";

import { Tutorial } from "../../models/entities/Tutorial/tutorial";
import { TutorialType } from "../../models/enums/tutorialType";

import { Container, Content, InputContainer } from "./styles";
import { CreateTutorialDto } from "../../models/dtos/createTutorial";

const TutorialPage: React.FC = () => {
  const [filter, setFilter] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tutorials, setTutorials] = useState<Tutorial[]>([]);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const { type } = useParams<{ type: string }>();

  const handleAdd = async (
    tutorial: CreateTutorialDto
  ): Promise<{ success: boolean; error: string }> => {
    let payload = {
      title: tutorial.title,
      file_url: tutorial.file_url,
      file_type: "video",
      description: tutorial.description,
      s3_key: null,
    };
    try {
      if (tutorial.image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", tutorial.image);

        const {
          data: { location, key, type: file_type },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.file_url = location;
        payload.file_type = file_type;
        payload.s3_key = key;
      }

      await api.post("/tutorial", {
        ...payload,
        type: +type,
      });
      setShouldSearch(true);
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setShouldSearch(true);
  }, [type]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get(`/tutorial/${type}`);
        setTutorials(content);
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };
    if (shouldSearch) {
      fetchData();
    }
  }, [shouldSearch, type]);

  const getHeader = (type: string) => {
    const headers = [
      {
        id: 1,
        type: TutorialType.STORE,
        icon: managementStore,
        title: "Gestão de Loja",
        description:
          "Otimize a gestão diária de sua loja e melhore os processos operacionais e administrativos.​",
      },
      {
        id: 2,
        type: TutorialType.DELIVERY,
        icon: managementStore,
        title: "App Delivery",
        description:
          "Aprenda como navegar e utilizar todas as funcionalidades do aplicativo de delivery de entrega do The Best Açaí.​",
      },
      {
        id: 3,
        type: TutorialType.DASHBOARD,
        icon: managementStore,
        title: "Dashboard",
        description:
          "Aprenda a utilizar o Dashboard para monitorar as métricas importantes e analisar o desempenho das vendas e funcionalidades para o Gestor de vendas.​",
      },
      {
        id: 4,
        type: TutorialType.GESTOR_VENDAS,
        icon: managementStore,
        title: "Gestor de Vendas",
        description:
          "Aprenda a utilizar o Gestor de vendas. Sistema principal para registro de vendas da loja The Best Açaí.​",
      },
      {
        id: 5,
        type: TutorialType.PORTAL_FRANQUEADO,
        icon: managementStore,
        title: "Portal do Franqueado",
        description:
          "Explore as funcionalidades do Portal do Franqueado para otimizar sua operação e suporte.​",
      },
      {
        id: 6,
        type: TutorialType.PADRÃO_QUALIDADE,
        icon: Investment,
        title: "Padrões de Excelência",
        description:
          "Aprenda a aplicar os padrões The Best Açaí para oferecer uma experiência de cliente distinta que define nossa marca.",
      },
      {
        id: 7,
        type: TutorialType.LIVE_FRANQUEADO,
        icon: LiveImg,
        title: "Lives dos Franqueados",
        description: "",
      },
      {
        id: 8,
        type: TutorialType.MENTORIAS,
        icon: LiveImg,
        title: "Mentorias",
        description: "",
      },
      {
        id: 9,
        type: TutorialType.AULA_IFOOD,
        icon: managementStore,
        title: "Aulas Ifood",
        description:
          "Domine as estratégias para aumentar sua visibilidade, vendas e operações na plataforma iFood.",
      },
      {
        id: 10,
        type: TutorialType.INTRODUCAO,
        icon: managementStore,
        title: "Introdução",
        description:
          "Introdução aos sistemas e fundamentos da gestão de loja na franquia The Best Açaí.",
      },
      {
        id: 11,
        type: TutorialType.CLUBE_THE_BEST,
        icon: managementStore,
        title: "Clube The Best",
        description:
          "Aprendizagem sobre os processos do programa de recompensas e cupons Clube The Best.",
      },
    ];

    return headers.find((header) => header.type === +type);
  };

  const handleFilter = (pattern?: string): Tutorial[] => {
    if (pattern) {
      return tutorials.filter(function (material) {
        return material.title.toLowerCase().includes(pattern.toLowerCase());
      });
    }
    return tutorials;
  };

  return (
    <Container>
      <PageContainer
        icon={getHeader(type)?.icon}
        title={getHeader(type)?.title}
        description={getHeader(type)?.description}
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {tutorials.length ? (
            <>
              <InputContainer id="input-button-container">
                <InputSearch value={filter} setValue={setFilter} />
                {verifyPermission("tutorial.add_new") && (
                  <ButtonAddNewTutorial handleAdd={handleAdd} />
                )}
              </InputContainer>

              <Content id="tutorialList-content">
                <TutorialList
                  tutorials={handleFilter(filter)}
                  setShouldSearch={setShouldSearch}
                />
              </Content>
            </>
          ) : (
            <NoDataTutorial handleAdd={handleAdd} />
          )}
        </>
      )}
    </Container>
  );
};

export default TutorialPage;
