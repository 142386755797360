import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  :root {
    --white: #ffffff;
    --white-50: #fafafa;
    --white-100: #f3f3f3;
    --white-200:  #E5E5E5;

    --gray-200: #e2e8f0;
    --gray-400: #a0aec0;
    --gray-500: #718096;
    --gray-600: #4A5568;
    --gray-700: #2d3748;
    --gray-700: #2d3748;
    --gray-900: #171923;


    --gray-300: #9A9A9A;
    --gray-850: #636363;
    --gray-800: #444444;

    --orange-100: #feebc8;
    --orange-200: #fbd38d;
    --orange-400: #ed8936;

    --yellow-400: #ecc94b;
    --yellow-500: #FFB800;
    --yellow-550: #E6A500;
    --yellow-600: #eba417;
    --yellow-opacity: rgba(255, 184, 0, 0.45);


    --blue-300: #63B3ED;
    --blue-400: #4299E1;

  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    
    ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
    
  }

  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;

    width: 100%;
    height: 100vw;
    font: 400 1rem "Roboto", sans-serif;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
  }

  *, button, input{
    border: 0;
    background: none;
    outline: none;
  }

  html {
    color: black;
  }
  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  
`;
