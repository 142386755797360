import styled from "styled-components";

import BackDash from "../../assets/image/backdash.png";
import NodataImg from "../../assets/svg/animated.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
    padding: 1%;
  }
`;

export const TopContent = styled.div`
  display: flex;
  margin-top: 2%;
  width: 100%;
  height: 40%;

  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  color: var(--gray-700);

  span {
    color: var(--yellow-500);
    font-weight: 600;
  }

  p {
    text-align: justify;
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 1600px) {
    h2 {
      margin-top: 10px;
      font-size: 1.2rem;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 1.6rem;
    }
  }

  @media only screen and (width: 1440px) {
    h2 {
      margin-top: 10px;
      font-size: 1.2rem;
    }

    p {
      font-size: 0.75rem;
      margin-bottom: 1.6rem;
    }
  }

  @media only screen and (width: 1366px) {
    h2 {
      margin-top: 1.5rem;
      font-size: 1rem;
    }

    p {
      font-size: 0.66rem;
      margin-bottom: 2.5rem;
    }
  }

  @media only screen and (width: 1360px) {
    h2 {
      margin-top: 1.5rem;
      font-size: 1rem;
    }

    p {
      font-size: 0.66rem;
      margin-bottom: 2.5rem;
    }
  }

  @media only screen and (width: 1280px) {
    h2 {
      margin-top: 1.5rem;
      font-size: 1.25rem;
    }

    p {
      font-size: 0.73rem;
      margin-bottom: 2.5rem;
    }
  }

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const DashAds = styled.div`
  background-image: url(${BackDash});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  color: var(--gray-700);

  h1 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;

    h1 {
      font-size: 1.55rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media only screen and (width: 1366px) {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.85rem;
    }
  }
  @media only screen and (width: 1360px) {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.85rem;
    }
  }
  @media only screen and (width: 1280px) {
    h1 {
      font-size: 1.6rem;
    }

    p {
      font-size: 0.85rem;
    }
  }
`;

export const ButtonAds = styled.a`
  background: var(--yellow-500);
  cursor: pointer;
  text-align: center;
  padding: 2%;
  color: var(--white);
  font-weight: 600;
  font-size: 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  :hover {
    background: var(--orange-400);
  }

  @media only screen and (width: 1366px) {
    font-size: 0.8rem;
  }
  @media only screen and (width: 1360px) {
    font-size: 0.8rem;
  }
`;

export const MiddleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6%;
  border-radius: 5px;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1% 2%;

  h2 {
    color: var(--white);
  }

  @media only screen and (max-width: 578px) {
    margin-top: 5%;
    height: 8%;
    padding: 1%;

    h2 {
      font-size: 1.25rem;
    }
  }

  @media only screen and (max-width: 1600px) {
    h2 {
      font-size: 1.25rem;
    }

    button {
      height: 2rem;
    }
  }

  @media only screen and (width: 1440px) {
    h2 {
      font-size: 1.25rem;
    }

    button {
      height: 2rem;
    }
  }

  @media only screen and (width: 1366px) {
    h2 {
      font-size: 1.2rem;
    }

    button {
      height: 1.8rem;
    }
  }
  @media only screen and (width: 1360px) {
    h2 {
      font-size: 1.2rem;
    }

    button {
      height: 1.8rem;
    }
  }

  @media only screen and (width: 1280px) {
    h2 {
      font-size: 1.2rem;
    }

    button {
      height: 2.2rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54%;
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: 100%;

  h1 {
    color: var(--gray-700);
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    color: var(--gray-700);
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 578px) {
    h1 {
      font-size: 1.7rem;
      margin-top: 10px;
    }
    p {
      font-size: 1rem;
      text-align: justify;
    }
  }

  @media only screen and (width: 1440px) {
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media only screen and (width: 1366px) {
    h1 {
      font-size: 2.25rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
  @media only screen and (width: 1360px) {
    h1 {
      font-size: 2.25rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
  @media only screen and (width: 1280px) {
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const Image = styled.div`
  width: 50%;
  height: 100%;
  background-image: url("${NodataImg}");
  background-repeat: no-repeat;
  background-size: cover;
`;
