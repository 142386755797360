import React from "react";
import {
  Container,
  IconContent,
  InfoContent,
  Icon,
  Title,
  Description,
} from "./styles";

interface IProps {
  icon: string | undefined;
  title: string | undefined;
  description: string | undefined;
}

const PageContainer: React.FC<IProps> = ({ icon, title, description }) => {
  return (
    <Container>
      <IconContent>
        <Icon src={icon} />
      </IconContent>
      <InfoContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </InfoContent>
    </Container>
  );
};

export default PageContainer;
