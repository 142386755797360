import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useParams, withRouter } from "react-router-dom";
import { verifyPermission } from "../../services/auth";

import PageContainer from "../../containers/PageContainer";

import InputSearch from "../../components/InputSearch";
import ButtonNewUpload from "../../components/ButtonNewUpload";
import NoDataInfo from "../../components/NoDataInfo";
import Spinner from "../../components/Spinner";
import CollapseCategoryMaterial from "../../components/CollapseCategoryMaterial";

import postMaterial from "../../assets/svg/postMaterial.svg";
import materialDelivery from "../../assets/svg/materialDelivery.svg";
import materialStore from "../../assets/svg/materialStore.svg";
import materialManual from "../../assets/image/manualIcon.png";

import { Container, Content, InputContainer } from "./styles";
import { MaterialType } from "../../models/enums/materialType";
import { MaterialCategory } from "../../models/entities/Material/materialCategory";
import LoadMore from "../../components/LoadMore";

const PostMaterial: React.FC = () => {
  const [filter, setFilter] = useState<string | undefined>();
  const [categories, setCategories] = useState<MaterialCategory[]>([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [loading, setLoading] = useState(true);
  const { type } = useParams<{ type: string }>();
  const [loadMoreVisible, setLoadMoreVisible] = useState<number>(5);
  const [visibleCategory, setVisibleCategory] = useState<number | null>(null);

  const handleAdd = async (
    title: string,
    description: string,
    image: File,
    category: number
  ): Promise<void> => {
    setLoading(false);

    const imageToUpload = new FormData();

    imageToUpload.append("file", image, image.name);

    const {
      data: { location, key, type: s3_type },
    } = await api.post("/s3-upload/upload", imageToUpload);

    const payload = {
      title,
      type: +type,
      url: location,
      key_s3: key,
      description,
      file_type: s3_type,
      materialCategory_id: category,
    };

    await api.post("/material", payload);

    setShouldSearch(true);
    setLoading(false);
  };

  useEffect(() => {
    setShouldSearch(true);
  }, [type]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get(`/materialCategory/${type}`);
      setCategories(data);
      setLoading(false);
      setShouldSearch(false);
    }
    if (shouldSearch) {
      fetchData();
    }
  }, [type, shouldSearch]);

  const getHeader = (type: string) => {
    const headers = [
      {
        id: 1,
        type: MaterialType.POST,
        icon: postMaterial,
        title: "Fotos para postagem",
        description:
          "Aqui você vai encontrar fotos para postagem no formato de Feed e Stories do Facebook e Instagram.​",
      },
      {
        id: 2,
        type: MaterialType.DELIVERY,
        icon: materialDelivery,
        title: "Fotos e arquivos para delivery",
        description:
          "Aqui você vai encontrar fotos e arquivos para postagem nas plataformas de delivery.​",
      },
      {
        id: 3,
        type: MaterialType.STORE,
        icon: materialStore,
        title: "Materiais para loja",
        description:
          "Aqui você vai encontrar os materiais genéricos que precisa para a sua loja ficar perfeita.​",
      },
      {
        id: 4,
        type: MaterialType.MANUAL,
        icon: materialManual,
        title: "Manuais",
        description:
          "Aqui você irá encontrar alguns materias para te auxiliar.​",
      },
    ];

    return headers.find((header) => header.type === +type);
  };

  return (
    <Container>
      <>
        <PageContainer
          icon={getHeader(type)?.icon}
          title={getHeader(type)?.title}
          description={getHeader(type)?.description}
        />
        {loading ? (
          <Spinner />
        ) : (
          <>
            {categories.length ? (
              <>
                <InputContainer id="input-button-container">
                  <InputSearch value={filter} setValue={setFilter} />
                  {verifyPermission("material.new_upload") && (
                    <ButtonNewUpload
                      handleAdd={handleAdd}
                      categories={categories}
                      material_type={+type}
                      setShouldSearch={setShouldSearch}
                    />
                  )}
                </InputContainer>

                <Content id="categoriesList-container">
                  {categories
                    .slice(0, loadMoreVisible)
                    .map((category, mapIndex) => (
                      <CollapseCategoryMaterial
                        index={mapIndex + 1}
                        key={category.id}
                        setShouldSearch={setShouldSearch}
                        category={category}
                        filter={filter}
                        setVisibleCategory={setVisibleCategory}
                        visibleCategory={visibleCategory}
                      />
                    ))}
                  {categories.length > loadMoreVisible && (
                    <LoadMore
                      title="Ver Mais"
                      setLoadMoreVisible={setLoadMoreVisible}
                    />
                  )}
                </Content>
              </>
            ) : (
              <NoDataInfo
                handleAdd={handleAdd}
                categories={categories}
                material_type={+type}
                setShouldSearch={setShouldSearch}
              />
            )}
          </>
        )}
      </>
    </Container>
  );
};

export default withRouter(PostMaterial);
