import React from "react";
import { getTokenInfo } from "../../services/auth";

import {
  Container,
  ModuleContainer,
  ModuleList,
  Module,
  TextInfo,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardInfo,
  Button,
} from "./styles";

import Introducao from "../../assets/image/BannersTutorial/Introdução.webp";
import GestorDeVendas from "../../assets/image/BannersTutorial/Gestor-de-vendas.webp";
import Dashboard from "../../assets/image/BannersTutorial/Dashboard.webp";
import ClubeTheBest from "../../assets/image/BannersTutorial/Club.webp";
import PortalFranquedo from "../../assets/image/BannersTutorial/PortalFranqueado.webp";
import Ifood from "../../assets/image/BannersTutorial/iFood.webp";
import AppDelivery from "../../assets/image/BannersTutorial/Delivery.webp";
import GestaoLoja from "../../assets/image/BannersTutorial/GestãoLoja.webp";
import Padroes from "../../assets/image/BannersTutorial/Padroes.webp";

import { TutorialType } from "../../models/enums/tutorialType";

const modules = [
  {
    id: 1,
    title: "Comece por aqui",
    description:
      "Introdução aos sistemas e fundamentos da gestão de loja na franquia The Best Açaí.",
    image: Introducao,
    link: `/tutorial/${TutorialType.INTRODUCAO}`,
  },
  {
    id: 2,
    title: "Gestor de Vendas",
    description:
      "Aprenda a utilizar o Gestor de vendas. Sistema principal para registro de vendas da loja The Best Açaí.",
    image: GestorDeVendas,
    link: `/tutorial/${TutorialType.GESTOR_VENDAS}`,
  },
  {
    id: 3,
    title: "Dashboard",
    description:
      "Aprenda a utilizar o Dashboard para monitorar as métricas importantes e analisar o desempenho das vendas e funcionalidades para o Gestor de vendas.",
    image: Dashboard,
    link: `/tutorial/${TutorialType.DASHBOARD}`,
  },
  {
    id: 4,
    title: "Clube The Best",
    description:
      "Aprendizagem sobre os processos do programa de recompensas e cupons Clube The Best.",
    image: ClubeTheBest,
    link: `/tutorial/${TutorialType.CLUBE_THE_BEST}`,
  },
  {
    id: 5,
    title: "Portal do Franqueado",
    description:
      "Explore as funcionalidades do Portal do Franqueado para otimizar sua operação e suporte.",
    image: PortalFranquedo,
    link: `/tutorial/${TutorialType.PORTAL_FRANQUEADO}`,
  },
  {
    id: 6,
    title: "Aulas iFood",
    description:
      "Domine as estratégias para aumentar sua visibilidade, vendas e operações na plataforma iFood.",
    image: Ifood,
    link: `/tutorial/${TutorialType.AULA_IFOOD}`,
  },
  {
    id: 7,
    title: "App Delivery",
    description:
      "Aprenda como navegar e utilizar todas as funcionalidades do aplicativo de delivery de entrega do The Best Açaí.",
    image: AppDelivery,
    link: `/tutorial/${TutorialType.DELIVERY}`,
  },
  {
    id: 8,
    title: "Gestão Loja",
    description:
      "Otimize a gestão diária de sua loja e melhore os processos operacionais e administrativos.",
    image: GestaoLoja,
    link: `/tutorial/${TutorialType.STORE}`,
  },
  {
    id: 8,
    title: "Padrões de Excelência",
    description:
      "Aprenda a aplicar os padrões The Best Açaí para oferecer uma experiência de cliente distinta que define nossa marca.",
    image: Padroes,
    link: `/tutorial/${TutorialType.PADRÃO_QUALIDADE}`,
  },
];

const Home: React.FC = () => {
  return (
    <Module>
      <TextInfo>
        <h1>
          Seja bem-vindo, <span>{getTokenInfo()?.name}!</span>
        </h1>
        <p>
          Aqui você tem acesso a uma vasta biblioteca de recursos projetados
          para enriquecer a operação e a gestão da sua franquia. Navegue por
          tutoriais detalhados, dicas de marketing, padrões de qualidade e tudo
          o que você precisa para fazer sua loja local prosperar.
        </p>
      </TextInfo>
      <Container>
        <ModuleContainer>
          <h2>
            Biblioteca de <span>conteúdos</span>
          </h2>
          <ModuleList>
            {modules.map((module) => (
              <Card>
                <CardMedia src={module.image} alt={module.title} />
                <CardContent>
                  <CardInfo>
                    <h3>{module.title}</h3>
                    <p>{module.description}</p>
                  </CardInfo>
                  <CardActions>
                    <a href={module.link}>
                      <Button>Acessar Conteúdo</Button>
                    </a>
                  </CardActions>
                </CardContent>
              </Card>
            ))}
          </ModuleList>
        </ModuleContainer>
      </Container>
    </Module>
  );
};

export default Home;
