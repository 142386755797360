import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../services/api";
import moment from "moment";

import NewIdeas from "../../assets/image/new-ideas.png";
import Spinner from "../Spinner";

import swal from "sweetalert";
import { Marketing as MarketingModel } from "../../models/entities/Marketing/marketing";

import {
  Container,
  Content,
  TitleModal,
  CloseIcon,
  NotificationList,
  NotificationCard,
  Sticker,
  NoData,
  ImageNewContainer,
  InfoNewContainer,
  ImageNew,
  ButtonView,
} from "./styles";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IProp extends RouteComponentProps<any> {
  isVisibleNotification: boolean;
  setIsVisibleNotification: Dispatch<SetStateAction<boolean>>;
}

const DropdownNotification: React.FC<IProp> = ({
  isVisibleNotification,
  setIsVisibleNotification,
  history,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [marketing, setMarketing] = useState<MarketingModel[]>([]);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get(
          `/marketing?dataInicial=${moment(new Date())
            .subtract(7, "days")
            .format("DD/MM/YYYY")}&dataFinal=${moment(new Date())
            .add(1, "day")
            .format("DD/MM/YYYY")}`
        );
        setMarketing(content);
        setShouldSearch(false);
      } catch {
        swal("", "Falha ao carregar registros", "error");
      } finally {
        setLoading(false);
      }
    };
    if (shouldSearch) {
      fetchData();
    }
  }, [shouldSearch]);

  return (
    <>
      {isVisibleNotification ? (
        <Container>
          <Content>
            <TitleModal>
              <span>Notificações</span>
            </TitleModal>

            <CloseIcon
              aria-label="Close Modal"
              onClick={() => setIsVisibleNotification((prev) => !prev)}
            />

            {marketing.length ? (
              <NotificationList>
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {marketing.map((marketing) => (
                        <>
                          <NotificationCard>
                            <ImageNewContainer>
                              {marketing.file_type.includes("video") ? (
                                <iframe
                                  allowFullScreen
                                  src={`https://www.youtube.com/embed/${marketing.file_url}`}
                                  title="YouTube video player"
                                  width="100%"
                                  height="100%"
                                />
                              ) : (
                                <ImageNew src={marketing.file_url} />
                              )}
                            </ImageNewContainer>
                            <InfoNewContainer>
                              <span>{marketing.title}</span>
                              <p>
                                {moment(marketing.created_at).format(
                                  "DD/MM/YYYY hh:mm"
                                )}
                              </p>

                              <ButtonView
                                onClick={() => history.push("/marketing")}
                              >
                                Visualizar
                              </ButtonView>
                            </InfoNewContainer>
                          </NotificationCard>
                        </>
                      ))}
                    </>
                  )}
                </>
              </NotificationList>
            ) : (
              <NoData>
                <Sticker src={NewIdeas} />
                <h2>Nenhuma notificação</h2>
                <p>
                  Mas não se preocupe, assim que tivermos uma novidade, ela será
                  notificada aqui para você conferir.
                </p>
              </NoData>
            )}
          </Content>
        </Container>
      ) : null}
    </>
  );
};

export default withRouter(DropdownNotification);
