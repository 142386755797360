import React from "react";
import { Container } from "./styles";

interface IProps {
  type: string;
  background_url: string;
  url: string;
}

const Banner: React.FC<IProps> = ({ type, background_url, url }) => {
  return (
    <Container
      id={`${type}-image`}
      background_url={background_url}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    ></Container>
  );
};

export default Banner;
