import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
`;

export const LoadMoreButton = styled.button`
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 8px;
  outline: none;
  background: var(--gray-200);
  width: 10rem;
  height: 2.5rem;
  margin: 10px 0;

  span {
    font-weight: bolder;
    font-size: 0.7rem;
    color: var(--gray-900);
    text-transform: uppercase;
  }

  :hover {
    filter: grayscale(1);
  }
`;
