import styled, { css } from "styled-components";

interface IProps {
  dragActive: boolean;
  dragReject: boolean;
}

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 2px dashed #cecece;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;

  background-color: ${(props) => (props.disabled ? "#e0e0e0" : "white")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${({ dragActive, dragReject }) => {
    if (dragReject) {
      return css`
        border-color: #e57878;
      `;
    }
    if (dragActive) {
      return css`
        border-color: #78e5d5;
      `;
    }
  }}

  &:hover {
    border: 2px dashed var(--gray-500);
  }
`;

const messageColors = {
  default: "#999",
  error: "#e57878",
  success: "#78e5d5",
};

export const UploadMessage = styled.p`
  display: flex;
  flex-direction: column;
  color: ${(props) => messageColors[props.typeof || "default"]};
  justify-content: center;
  align-items: center;

  font-size: 14px;
  margin-bottom: 0;
`;

export const Container = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const UploadIcon = styled.img`
  height: 80px;
  width: 80px;
`;
