import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import Upload from "../Upload";
import ButtonSave from "../ButtonSave";
import ModalNewCategory from "../ModalNewCategory";
import { MaterialCategory } from "../../models/entities/Material/materialCategory";
import swal from "sweetalert";

import {
  Container,
  Content,
  CloseIcon,
  TitleModal,
  UploadContainer,
  TitleContainer,
  Input,
  TitleContet,
  SaveButtonContainer,
  DescriptionContent,
  Textarea,
  Select,
  Option,
  CategoryContent,
  AddIcon,
  ButtonNewCategory,
  AddCategoryContent,
  EditCategory,
} from "./styles";

interface IProp {
  material_type: number;
  categories: MaterialCategory[];
  handleAdd: (
    title: string,
    description: string,
    image: File,
    category: number
  ) => Promise<void>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalUploadImage: React.FC<IProp> = ({
  setIsVisible,
  handleAdd,
  children,
  categories,
  material_type,
  setShouldSearch,
}) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<number>(0);
  const [title, setTitle] = useState<string | null>();
  const [description, setDescription] = useState<string | null>();
  const [image, setImage] = useState<File | null>(null);
  const [isVisibleModalCategory, setIsVisibleModalCategory] = useState(false);

  const modalRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsVisible(false);
    }
  };

  const openModalNewCategory = () => {
    setCategory(0);
    setIsVisibleModalCategory((prev) => !prev);
  };

  const handleUpload = async (files: File[]): Promise<void> => {
    setImage(files[0]);
  };

  const handleSave = async () => {
    try {
      if (loading || !title || !image || !description || !category) {
        return swal(
          "Oops!",
          "Preencha os campos de titulo, descrição, categoria e faça o upload corretamente.",
          "warning"
        );
      }
      setLoading(true);
      await handleAdd(title, description, image, category);
      swal("Upload salvo com sucesso!", "", "success");
      setIsVisible(false);
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao registrar material";
      swal("Oops!", error, "warning");
    } finally {
      setLoading(false);
    }
  };

  const maxSize = 1024 * 1024 * 1024;

  const _categories = [{ id: 0, name: "Escolha uma opção" }, ...categories];
  return (
    <>
      <Container ref={modalRef} onClick={closeModal}>
        <Content>
          <TitleModal>
            <span>Nova postagem</span>
          </TitleModal>
          <TitleContainer>
            <TitleContet>
              <span>Título</span>
              <Input
                id="title-input"
                placeholder="Digite o título"
                onChange={({ target: { value } }) => setTitle(value)}
              />
            </TitleContet>

            <CategoryContent>
              <span>Categoria</span>
              <AddCategoryContent>
                <Select
                  id="selectCategory-droplist"
                  placeholder="Escolha uma opção"
                  value={category}
                  onChange={({ currentTarget: { value } }) =>
                    setCategory(+value)
                  }
                >
                  {_categories.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>

                <ButtonNewCategory
                  id="newCategory-button"
                  onClick={openModalNewCategory}
                >
                  <AddIcon />
                </ButtonNewCategory>
              </AddCategoryContent>
            </CategoryContent>

            {category !== 0 && (
              <EditCategory onClick={() => setIsVisibleModalCategory(true)}>
                Editar Categoria
              </EditCategory>
            )}

            <DescriptionContent>
              <span>Descrição</span>
              <Textarea
                id="description-textarea"
                onChange={({ target: { value } }) => setDescription(value)}
                placeholder="Informe uma descrição"
              />
            </DescriptionContent>

            <UploadContainer>
              <Upload
                onUpload={handleUpload}
                image={image}
                setImage={setImage}
                typeFile={"image/*,application/pdf,video/mp4"}
                maxSize={maxSize}
              />
            </UploadContainer>
          </TitleContainer>

          <SaveButtonContainer>
            <ButtonSave
              loading={loading}
              description="Salvar Upload"
              onClick={() => handleSave()}
            />
          </SaveButtonContainer>

          <CloseIcon
            id="close-button"
            aria-label="Close Modal"
            onClick={() => setIsVisible((prev) => !prev)}
          />
          {children}
        </Content>
      </Container>

      <ModalNewCategory
        material_type={material_type}
        isVisibleModalCategory={isVisibleModalCategory}
        setIsVisibleModalCategory={setIsVisibleModalCategory}
        setShouldSearch={setShouldSearch}
        category={categories.find((_category) => _category.id === category)}
      />
    </>
  );
};

export default ModalUploadImage;
