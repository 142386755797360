import styled from "styled-components";

import { CloseOutline, Add } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.5s 0.5s ease-in-out;
  transition: all 0.5s 0.5s ease-in-out;
  z-index: 10;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--white);
  width: 28rem;
  height: 35rem;
  border-radius: 10px;

  @media only screen and (max-width: 578px) {
    width: 90%;
    height: 85%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
  margin-top: 12%;
  padding: 0% 4% 0% 4%;

  @media only screen and (max-width: 578px) {
    margin-top: 20%;
  }

  span {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--gray-500);
  }
`;

export const TitleContet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
`;

export const Input = styled.input`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 2.5rem;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
`;

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
`;

export const AddCategoryContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const ButtonNewCategory = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  background: var(--gray-900);
  color: var(--white);
  padding: 2%;

  :hover {
    background: var(--gray-700);
  }
`;

export const AddIcon = styled(Add)`
  width: 25px;
  height: 25px;
  @media only screen and (max-width: 578px) {
    width: 20px;
    height: 20px;
  }
`;

export const Select = styled.select`
  width: 85%;
  height: 2.5rem;
  font-size: 0.9rem;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  color: var(--gray-600);
`;

export const Option = styled.option`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
`;

export const EditCategory = styled.a`
  width: 100%;
  margin-bottom: 5px;
  text-decoration: underline;
  font-weight: 500;
  color: orange;
  cursor: pointer;
  font-size: 0.8rem;
`;

export const Textarea = styled.textarea`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 6rem;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--gray-200);
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70%;
  height: 32px;
  padding: 0;
  z-index: 10;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 20%;
  padding: 4%;
`;
