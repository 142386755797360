import React, { useState } from "react";
import { verifyPermission } from "../../services/auth";
import { CreateTutorialDto } from "../../models/dtos/createTutorial";
import ModalNewTutorial from "../ModalNewTutorial";

import { Button, AddIcon } from "./styles";

interface IProps {
  handleAdd: (
    tutorial: CreateTutorialDto
  ) => Promise<{ success: boolean; error: string }>;
}

const ButtonAddNewTutorial: React.FC<IProps> = ({ handleAdd }) => {
  const [isVisible, setIsVisible] = useState(false);
  const openModal = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <>
      {verifyPermission("tutorial.add_new") && (
        <Button id="newTutorial-button" onClick={openModal}>
          <AddIcon />
          <span>Adicionar Novo</span>
        </Button>
      )}
      <ModalNewTutorial
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        handleAdd={handleAdd}
      />
    </>
  );
};

export default ButtonAddNewTutorial;
