import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { MaterialType } from "../../models/enums/materialType";
import { TutorialType } from "../../models/enums/tutorialType";

import Logo from "../../assets/image/logo_rounded.png";

import {
  Container,
  TopContent,
  MiddleContent,
  BottomContent,
  DropDown,
  MenuCards,
  MenuItem,
  HomeIcon,
  DownloadIcon,
  TutorialIcon,
  QuanlityIcon,
  MarketingIcon,
  BlogIcon,
  ArrowDown,
  DropDownContent,
  ArrowUp,
  LogoContent,
  LabelLogo,
  CloseIcon,
  LiveIcon,
  PdfIcon,
  CalendarIcon,
} from "./styles";

interface IProps extends RouteComponentProps<any> {
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

interface Menu {
  id: number;
  menu: string;
  icon: JSX.Element;
  path: string;
  hasChildren: boolean;
  childrens: {
    id: number;
    menu: string;
    path: string;
  }[];
}

const SideMenu: React.FC<IProps> = ({ history, location, setIsVisible }) => {
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);

  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);

  const handleOpenMenu = (id: number | null): void => {
    setOpenedMenu(id);
  };

  const isSameId = (id: number): boolean => {
    return openedMenu === id;
  };

  const isSamePath = (selectedPath: string[]): boolean => {
    return selectedPath.some((_path) => _path === path);
  };

  const isFindChildPaths = (menu: Menu): string[] => {
    return menu.childrens.map((child) => child.path);
  };

  const redirect = (path: string): void => {
    history.push(path);
  };

  const menus: Menu[] = [
    {
      id: 1,
      menu: "Página Inicial",
      icon: <HomeIcon />,
      path: "/",
      hasChildren: false,
      childrens: [],
    },
    {
      id: 2,
      menu: "Live dos Franqueados",
      icon: <LiveIcon />,
      path: `/tutorial/${TutorialType.LIVE_FRANQUEADO}`,
      hasChildren: false,
      childrens: [],
    },
    {
      id: 3,
      menu: "Mentorias",
      icon: <LiveIcon />,
      path: `/tutorial/${TutorialType.MENTORIAS}`,
      hasChildren: false,
      childrens: [],
    },
    {
      id: 4,
      menu: "Materiais",
      icon: <DownloadIcon />,
      path: "",
      hasChildren: true,
      childrens: [
        {
          id: 1,
          menu: "Postagem",
          path: `/material/${MaterialType.POST}`,
        },
        {
          id: 2,
          menu: "Delivery",
          path: `/material/${MaterialType.DELIVERY}`,
        },
        {
          id: 3,
          menu: "Loja",
          path: `/material/${MaterialType.STORE}`,
        },
        {
          id: 4,
          menu: "Manuais",
          path: `/material/${MaterialType.MANUAL}`,
        },
      ],
    },
    {
      id: 5,
      menu: "Treinamento",
      icon: <TutorialIcon />,
      path: "",
      hasChildren: true,
      childrens: [
        {
          id: 1,
          menu: "Introdução",
          path: `/tutorial/${TutorialType.INTRODUCAO}`,
        },
        {
          id: 2,
          menu: "Gestor de Vendas",
          path: `/tutorial/${TutorialType.GESTOR_VENDAS}`,
        },
        {
          id: 3,
          menu: "Dashboard",
          path: `/tutorial/${TutorialType.DASHBOARD}`,
        },
        {
          id: 4,
          menu: "Clube The Best",
          path: `/tutorial/${TutorialType.CLUBE_THE_BEST}`,
        },
        {
          id: 5,
          menu: "Portal do Franqueado",
          path: `/tutorial/${TutorialType.PORTAL_FRANQUEADO}`,
        },
        {
          id: 6,
          menu: "Aulas Ifood",
          path: `/tutorial/${TutorialType.AULA_IFOOD}`,
        },
        {
          id: 7,
          menu: "App Delivery",
          path: `/tutorial/${TutorialType.DELIVERY}`,
        },
        {
          id: 8,
          menu: "Gestão de Loja",
          path: `/tutorial/${TutorialType.STORE}`,
        },
      ],
    },
    {
      id: 6,
      menu: "Padrão de Qualidade",
      icon: <QuanlityIcon />,
      path: "",
      hasChildren: true,
      childrens: [
        {
          id: 1,
          menu: "Padrões de Excelência",
          path: `/tutorial/${TutorialType.PADRÃO_QUALIDADE}`,
        },
        {
          id: 2,
          menu: "Tabelas e Manuais",
          path: "/table-manual",
        },
      ],
    },
    {
      id: 7,
      menu: "Marketing",
      icon: <MarketingIcon />,
      path: "/marketing",
      hasChildren: false,
      childrens: [],
    },
    {
      id: 8,
      menu: "Blog",
      icon: <BlogIcon />,
      path: "/blog",
      hasChildren: false,
      childrens: [],
    },
    {
      id: 9,
      menu: "Calendário",
      icon: <CalendarIcon />,
      path: "/calendar",
      hasChildren: false,
      childrens: [],
    },
  ];

  return (
    <Container>
      <TopContent>
        <LogoContent>
          <LabelLogo src={Logo} />
        </LogoContent>
        <CloseIcon onClick={() => setIsVisible((prev) => !prev)} />
      </TopContent>

      <MiddleContent>
        {menus.map((menu) =>
          menu.hasChildren ? (
            <DropDown key={menu.id} id={`${menu.id}`}>
              <MenuCards
                isActived={isSamePath([menu.path, ...isFindChildPaths(menu)])}
                onClick={() =>
                  handleOpenMenu(isSameId(menu.id) ? null : menu.id)
                }
              >
                {menu.icon}
                {menu.menu}
                {isSameId(menu.id) ? <ArrowUp /> : <ArrowDown />}
              </MenuCards>

              {isSameId(menu.id) && (
                <DropDownContent id={`${menu.id}`}>
                  {menu.childrens.map((children) => (
                    <MenuItem
                      id={`${children.id}`}
                      isActived={isSamePath([children.path])}
                      key={children.id}
                      onClick={() => redirect(children.path)}
                    >
                      {children.menu}
                    </MenuItem>
                  ))}
                </DropDownContent>
              )}
            </DropDown>
          ) : (
            <MenuCards
              id={`${menu.id}`}
              key={menu.id}
              isActived={isSamePath([menu.path, ...isFindChildPaths(menu)])}
              onClick={() => redirect(menu.path)}
            >
              {menu.icon}
              {menu.menu}
            </MenuCards>
          )
        )}

        <a
          href="https://upload-service-portal.s3.amazonaws.com/f21cea6ab62982a5939837fc4a8ec02ec73ebd2b"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuCards isActived={false} style={{ color: "white" }}>
            {" "}
            <PdfIcon style={{ fill: "white" }} /> Tutorial NFC-e
          </MenuCards>
        </a>
      </MiddleContent>

      <BottomContent></BottomContent>
    </Container>
  );
};

export default withRouter(SideMenu);
