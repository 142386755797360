import styled from "styled-components";

import { CloseOutline } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.5s 0.5s ease-in-out;
  transition: all 0.5s 0.5s ease-in-out;
  z-index: 1000;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--white);
  width: 28rem;
  height: 35rem;
  border-radius: 10px;

  @media only screen and (max-width: 578px) {
    width: 90%;
    height: 80%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
  margin-top: 12%;
  padding: 0% 4% 0% 4%;

  span {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--gray-500);
  }

  @media only screen and (max-width: 578px) {
    margin-top: 20%;
    overflow-y: scroll;
  }
`;

export const TitleContet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20%;
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 50%;
  margin-top: 5px;
`;

export const Input = styled.input`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 2.5rem;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
`;

export const Textarea = styled.textarea`
  justify-content: center;
  padding: 3%;
  width: 100%;
  height: 6rem;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Roboto";
`;

export const CloseIcon = styled(CloseOutline)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  user-select: none;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--gray-200);
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70%;
  height: 32px;
  padding: 0;
  z-index: 10;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 20%;
  padding: 4%;
`;

export const ListImages = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 50%;

  @media only screen and (max-width: 578px) {
    height: fit-content;
    overflow-y: none;
  }
`;

export const CardListImages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 0% 3% 0% 3%;

  @media only screen and (max-width: 578px) {
  }
`;

export const Preview = styled.img`
  width: 20%;
  height: 80%;
  object-fit: cover;
`;

export const Remove = styled.button`
  color: red;
`;
