import styled from "styled-components";

export const Input = styled.input`
  width: 50%;
  height: 2.5rem;
  border-radius: 50px;
  outline: none;
  border: none;
  background-color: var(--gray-200);
  padding: 0 20px 0 20px;
  border: 0px;
  font-size: 1rem;
  color: var(--gray-700);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media only screen and (max-width: 578px) {
    width: 80%;
  }
`;
