import styled, { css } from "styled-components";

import {
  ArrowIosDownwardOutline,
  ArrowIosUpwardOutline,
} from "../../styles/Icons";

interface IContainer {
  src?: string;
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 3%;
  width: 100%;
  height: 17%;
  border-radius: 10px;
  user-select: none;
  margin-bottom: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  ${({ src }) => css`
    background-image: url(${src});
    background-repeat: no-repeat;
    background-size: cover;
  `}

  @media only screen and (max-width: 575px) {
    width: 95%;
    margin: 10px;
  }
  @media only screen and (max-width: 1280px) {
    height: 15%;
  }
`;

export const NameCategory = styled.label`
  color: var(--white);
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;

  @media only screen and (max-width: 575px) {
    font-size: 1.2rem;
  }
`;

const IconCSS = css`
  width: 30px;
  height: 30px;
  color: white;
`;

export const ArrowDownIcon = styled(ArrowIosDownwardOutline)`
  ${IconCSS}
`;

export const ArrowUpIcon = styled(ArrowIosUpwardOutline)`
  ${IconCSS}
`;

export const Dropdown = styled.div`
  z-index: 100;
  width: 100%;
  height: max-content;
  top: 47%;
  margin-bottom: 10px;
  background: white;
`;

export const DeleteCategory = styled.button`
  color: white;
  background: red;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  text-decoration: underline;
`;
