import React, { useState, Dispatch, SetStateAction } from "react";

import { decodeToken, verifyPermission } from "../../services/auth";
import Spinner from "../Spinner";
import CarouselBlog from "../CarouselBlog";
import { Blog } from "../../models/entities/Blog/blog";
import moment from "moment";
import CardComment from "../CardComment";

import swal from "sweetalert";

import {
  Container,
  ImagesContent,
  Avatar,
  AvatarContent,
  UserName,
  Title,
  Description,
  InfoUser,
  Actions,
  LikeIcon,
  CommentIcon,
  LikeContainet,
  CommentContainer,
  DislikeContainer,
  DislikeIcon,
  ActionsContainer,
  ActionsAdm,
  RemoveIcon,
  TextPost,
  ImagemContainer,
  InfoPost,
  CommentsContainer,
  NewCommentContainer,
  TextAreaInput,
  ButtonNewCommentSave,
  Content,
} from "./styles";

interface IProps {
  blog: Blog;

  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  handleAddComment: (
    comment: string,
    parent_id
  ) => Promise<{ success: boolean; error: string }>;
  handleDeleteComment: (
    id: number
  ) => Promise<{ success: boolean; error: string }>;
  handleDelete: (id: number) => Promise<{ success: boolean; error: string }>;

  handleLike: (payload: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }) => Promise<{ success: boolean; error: string }>;
  parent_id: number;
}

const CardBlog: React.FC<IProps> = ({
  blog,
  setShouldSearch,
  handleAddComment,
  handleDeleteComment,
  handleLike,
  handleDelete,
  parent_id,
}) => {
  const [liking, setLiking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);
  const [comment, setComment] = useState<string | undefined>();

  const onCommentAdd = async (): Promise<void> => {
    try {
      if (!comment) {
        return;
      }
      setLoading(true);
      await handleAddComment(comment, parent_id);
      setComment(undefined);
      setLoading(false);
      setShouldSearch(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onCommentDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      swal({
        title: "Excluir comentário",
        text: "Você tem certeza de que quer excluir este comentário?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await handleDeleteComment(id);
          swal("Comentário excluído com sucesso!", {
            icon: "success",
          });
        }
        setShouldSearch(true);
        setLoading(true);
      });
      return { success: true, error: "" };
    } catch (err: any) {
      swal("Oops!", " Erro ao excluir comentário", {
        icon: "error",
      });
      setLoading(false);
      const error =
        err.response?.data?.message || "Falha ao realizar registro no blog";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleOpenMenu = (id: number | null): void => {
    setOpenedMenu(id);
  };

  const onDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      swal({
        title: "Exclusão",
        text: "Você tem certeza de que quer excluir?",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then(async (willDelete) => {
        if (willDelete) {
          await handleDelete(id);
          swal("Excluído com sucesso!", {
            icon: "success",
          });
        }
        setShouldSearch(true);
        setLoading(true);
      });
      return { success: true, error: "" };
    } catch (err: any) {
      setLoading(false);
      const error =
        err.response?.data?.message || "Falha ao registrar tutorial";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const onLike = async ({
    parent_id,
    like,
    deslike,
  }: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }): Promise<void> => {
    try {
      setLiking(true);
      await handleLike({ parent_id, like, deslike });
      setShouldSearch(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLiking(false);
    }
  };

  const isSameId = (id: number): boolean => {
    return openedMenu === id;
  };

  const handleLikeOrDeslike = (prop: string): boolean => {
    const user_id = decodeToken().id;
    const hasLike = blog.blogLikes.find(
      (blogLike) => blogLike.user_id === user_id
    );
    if (!hasLike) {
      return false;
    }
    return hasLike[prop];
  };

  moment.locale("pt");
  moment.updateLocale("pt", {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  });

  return (
    <>
      <Container key={blog.id}>
        <ImagemContainer>
          <ImagesContent>
            <CarouselBlog images={blog.blogFiles} />
          </ImagesContent>
        </ImagemContainer>

        <InfoPost>
          <InfoUser>
            <AvatarContent>
              <Avatar src="https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png" />
            </AvatarContent>

            <UserName id="username-container">
              <h3>{blog.user_name}</h3>
              <span>
                {moment(blog.created_at).format(
                  "DD [de] MMMM [de] YYYY [às] HH:mm"
                )}
              </span>
            </UserName>

            <ActionsAdm>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {decodeToken().id === blog.user_id && (
                    <RemoveIcon onClick={() => onDelete(blog.id)} />
                  )}
                </>
              )}
            </ActionsAdm>
          </InfoUser>

          <Content>
            <TextPost>
              <Title id="title-content">
                <h1>{blog.title}</h1>
              </Title>
              <Description id="description-content">
                <p>{blog.description}</p>
              </Description>
            </TextPost>

            <CommentsContainer id="commentsList-container">
              {blog.blogComment.map((comment, indexMap) => (
                <CardComment
                  index={indexMap + 1}
                  key={comment.id}
                  image_avatar={
                    "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
                  }
                  nickname={comment.user_name}
                  created_at={moment(comment.created_at).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                  comment={comment.comment}
                  handleDelete={onCommentDelete}
                  id={comment.id}
                  user_id={comment.user_id}
                />
              ))}
            </CommentsContainer>
          </Content>

          <ActionsContainer>
            <Actions>
              {liking ? (
                <Spinner />
              ) : (
                <>
                  <LikeContainet>
                    <LikeIcon
                      id="like-button"
                      hasClicked={handleLikeOrDeslike("like")}
                      onClick={() =>
                        onLike({
                          parent_id: blog.id,
                          like: true,
                          deslike: false,
                        })
                      }
                    />
                    <span>{blog.like}</span>
                  </LikeContainet>
                  <DislikeContainer>
                    <DislikeIcon
                      id="dislike-button"
                      hasClicked={handleLikeOrDeslike("deslike")}
                      onClick={() =>
                        onLike({
                          parent_id: blog.id,
                          like: false,
                          deslike: true,
                        })
                      }
                    />
                    <span>{blog.deslike}</span>
                  </DislikeContainer>
                </>
              )}

              <CommentContainer
                onClick={() =>
                  handleOpenMenu(isSameId(blog.id) ? null : blog.id)
                }
              >
                <CommentIcon />
                <span>{blog.blogComment.length}</span>
              </CommentContainer>
            </Actions>
          </ActionsContainer>

          <NewCommentContainer>
            <TextAreaInput
              id="newComment-textarea"
              aria-label="Adicione um comentário..."
              placeholder="Adicione um comentário..."
              autoComplete="off"
              autoCorrect="off"
              value={comment}
              onChange={({ target: { value } }) => setComment(value)}
              disabled={!verifyPermission("add_comment")}
            />

            {loading ? (
              <Spinner />
            ) : (
              <ButtonNewCommentSave
                id="saveNewComment-button"
                onClick={() => onCommentAdd()}
                disabled={!verifyPermission("add_comment")}
              >
                Publicar
              </ButtonNewCommentSave>
            )}
          </NewCommentContainer>
        </InfoPost>
      </Container>
    </>
  );
};

export default CardBlog;
