import React, { Dispatch, SetStateAction } from "react";
import ButtonNewUpload from "../ButtonNewUpload";
import { verifyPermission } from "../../services/auth";
import { MaterialCategory } from "../../models/entities/Material/materialCategory";

import { Container, InfoContainer } from "./styles";

interface IProps {
  material_type: number;
  categories: MaterialCategory[];
  handleAdd: (
    title: string,
    description: string,
    image: File,
    category: number
  ) => Promise<void>;

  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const NoDataInfo: React.FC<IProps> = ({
  handleAdd,
  categories,
  material_type,
  setShouldSearch,
}) => {
  return (
    <Container>
      <InfoContainer>
        <h1>Espaço vazio por aqui</h1>
        {verifyPermission("material.new_upload") && (
          <ButtonNewUpload
            handleAdd={handleAdd}
            categories={categories}
            material_type={material_type}
            setShouldSearch={setShouldSearch}
          />
        )}
      </InfoContainer>
    </Container>
  );
};

export default NoDataInfo;
