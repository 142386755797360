import React, { Dispatch, SetStateAction } from "react";
import swal from "sweetalert";

import CardTutorial from "../../components/CardTutorial";
import { Marketing } from "../../models/entities/Marketing/marketing";
import api from "../../services/api";

import { Container } from "./styles";

interface IProps {
  marketing: Marketing[];
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const MarketingList: React.FC<IProps> = ({ marketing, setShouldSearch }) => {
  const handleAddComment = async (
    comment: string,
    marketing_id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/marketingComment", {
        marketing_id,
        comment,
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message ||
        "Falha ao realizar registro no marketing";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDeleteComment = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/marketingComment/${id}`);
      swal("Comentário excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error =
        err.response?.data?.message || "Falha ao excluír comentário";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleDelete = async (
    id: number
  ): Promise<{ success: boolean; error: string }> => {
    try {
      await api.delete(`/marketing/${id}`);
      swal("Post do marketing excluído com sucesso!", {
        icon: "success",
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao excluir novidade";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleUpdate = async (
    marketing_id: number,
    data: any
  ): Promise<{ success: boolean; error: string }> => {
    const payload = {
      title: data.title,
      type: data.type,
      file_url: data.file_url,
      content: data.content,
      file_type: "video",
      s3_key: null,
    };
    try {
      if (data.image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", data.image);

        const {
          data: { location, key, type: file_type },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.file_url = location;
        payload.file_type = file_type;
        payload.s3_key = key;
      }
      await api.put(`/marketing/${marketing_id}`, payload);
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao editar novidade";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  const handleLike = async ({
    parent_id,
    like,
    deslike,
  }: {
    parent_id: number;
    like: boolean;
    deslike: boolean;
  }): Promise<{ success: boolean; error: string }> => {
    try {
      await api.post("/marketingLike", {
        marketing_id: parent_id,
        like,
        deslike,
      });
      return { success: true, error: "" };
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao registrar like";
      return {
        success: false,
        error: Array.isArray(error) ? error.join(", ") : error,
      };
    }
  };

  return (
    <Container>
      {marketing
        .map((marketing) => ({
          id: marketing.id,
          title: marketing.title,
          file_url: marketing.file_url,
          file_type: marketing.file_type,
          description: marketing.content,
          like: marketing.like,
          deslike: marketing.deslike,
          created_at: marketing.created_at,
          updated_at: marketing.updated_at,
          deleted_at: marketing.deleted_at,
          comments: marketing.marketingComments.map((marketingComment) => ({
            id: marketingComment.id,
            parent_id: marketingComment.marketing_id,
            user_id: marketingComment.user_id,
            user_name: marketingComment.user_name,
            comment: marketingComment.comment,
            created_at: marketingComment.created_at,
          })),
          likes: marketing.marketingLikes.map((marketingLike) => ({
            id: marketingLike.id,
            like: marketingLike.like,
            parent_id: marketingLike.marketing_id,
            deslike: marketingLike.deslike,
            user_id: marketingLike.user_id,
            user_name: marketingLike.user_name,
            created_at: marketingLike.created_at,
            updated_at: marketingLike.updated_at,
          })),
        }))
        .map((card, index) => (
          <CardTutorial
            key={card.id}
            card={card}
            position={index + 1}
            setShouldSearch={setShouldSearch}
            handleAddComment={handleAddComment}
            handleDeleteComment={handleDeleteComment}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            handleLike={handleLike}
          />
        ))}
    </Container>
  );
};

export default MarketingList;
