import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import api from "../../services/api";
import ButtonSave from "../ButtonSave";

import { MaterialCategory } from "../../models/entities/Material/materialCategory";

import Upload from "../Upload";
import swal from "sweetalert";

import {
  Container,
  Content,
  CloseIcon,
  TitleModal,
  SaveButtonContainer,
  UploadContainer,
  TitleContent,
  Input,
  TitleContainer,
} from "./styles";

interface IProp {
  material_type: number;
  isVisibleModalCategory: boolean;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setIsVisibleModalCategory: Dispatch<SetStateAction<boolean>>;
  category?: MaterialCategory;
}

const ModalNewCategory: React.FC<IProp> = ({
  isVisibleModalCategory,
  setIsVisibleModalCategory,
  material_type,
  setShouldSearch,
  category,
}) => {
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);

  const modalRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsVisibleModalCategory(false);
    }
  };

  const handleUpload = async (files: File[]): Promise<void> => {
    setImage(files[0]);
  };

  const handleAdd = async (): Promise<void> => {
    try {
      const method = category?.id ? "put" : "post";
      if (method === "post") {
        if (!image || !title) {
          return swal(
            "Oops!",
            "Preencha o campo de título e faça o upload da imagem corretamente.",
            "warning"
          );
        }
      }
      setLoading(true);

      let payload: {
        name?: string;
        type: string;
        url?: string;
        s3_key?: string;
      } = {
        name: title.length ? title : category?.name,
        type: material_type.toString(),
      };

      if (image) {
        const imageToUpload = new FormData();

        imageToUpload.append("file", image as File);

        const {
          data: { location, key },
        } = await api.post("/s3-upload/upload", imageToUpload);

        payload.url = location;
        payload.s3_key = key;
      }

      const url = category?.id
        ? `/materialCategory/${category?.id}`
        : "/materialCategory";

      await api[method](url, payload);
      swal("Categoria salva com sucesso!", "", "success");

      setIsVisibleModalCategory(false);
      setShouldSearch(true);
    } catch (err: any) {
      const error = err.response?.data?.message || "Falha ao editar categoria";

      swal("Oops!", Array.isArray(error) ? error.join(", ") : error, {
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const maxSize = 15 * 1024 * 1024;

  return (
    <>
      {isVisibleModalCategory ? (
        <Container ref={modalRef} onClick={closeModal}>
          <Content>
            <TitleModal>
              <span>Criar nova categoria</span>
            </TitleModal>
            <CloseIcon
              aria-label="Close Modal"
              onClick={() => setIsVisibleModalCategory((prev) => !prev)}
            />

            <TitleContainer>
              <TitleContent>
                <span>Título</span>
                <Input
                  defaultValue={category?.name}
                  placeholder="Digite o título"
                  onChange={({ target: { value } }) => setTitle(value)}
                />
              </TitleContent>

              <UploadContainer>
                <Upload
                  onUpload={handleUpload}
                  image={image}
                  setImage={setImage}
                  typeFile={"image/*"}
                  maxSize={maxSize}
                />
              </UploadContainer>
              <span>Proporção: 1318x170</span>
            </TitleContainer>

            <SaveButtonContainer>
              <ButtonSave
                loading={loading}
                description="Salvar Upload"
                onClick={() => handleAdd()}
              />
            </SaveButtonContainer>
          </Content>
        </Container>
      ) : null}
    </>
  );
};

export default ModalNewCategory;
