import React from "react";
import { Button, CheckIcon } from "./styles";
import Spinner from "../Spinner";

interface IProps {
  loading?: boolean;
  description: string;
  disabled?: boolean;
  onClick: () => void;
}

const ButtonSave: React.FC<IProps> = ({
  description,
  onClick,
  loading,
  disabled,
}) => {
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Button
            id="save-button"
            onClick={onClick}
            disabled={loading || disabled}
          >
            <CheckIcon />
            <span>{description}</span>
          </Button>
        </>
      )}
    </>
  );
};

export default ButtonSave;
