import styled from "styled-components";

import Carousel from "react-elastic-carousel";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100%;
  .rec-carousel {
    width: 100% !important;
    height: 100% !important;
  }

  .rec-slider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ICarousel = styled(Carousel)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 10px 0;
`;

export const Images = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
