export enum TutorialType {
  STORE = 0,
  DELIVERY = 1,
  DASHBOARD = 2,
  GESTOR_VENDAS = 3,
  PORTAL_FRANQUEADO = 4,
  PADRÃO_QUALIDADE = 5,
  LIVE_FRANQUEADO = 6,
  AULA_IFOOD = 7,
  MENTORIAS = 8,
  CLUBE_THE_BEST = 9,
  INTRODUCAO = 10,
}
