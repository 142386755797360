import React from "react";
import { decodeToken, verifyPermission } from "../../services/auth";

import {
  Container,
  AvatarContent,
  CommentInfo,
  Avatar,
  Nickname,
  Comment,
} from "./styles";

interface IProps {
  index: number;
  image_avatar: string;
  nickname: string;
  comment: string;
  created_at: string;
  user_id: number;
  id: number;
  handleDelete: (id: number) => Promise<{ success: boolean; error: string }>;
}
const CardComment: React.FC<IProps> = ({
  index,
  image_avatar,
  nickname,
  comment,
  created_at,
  handleDelete,
  id,
  user_id,
}) => {
  return (
    <Container>
      <AvatarContent>
        <Avatar src={image_avatar} />
      </AvatarContent>

      <CommentInfo id={`comment-${index}-container`}>
        <Nickname>
          {nickname}
          {(decodeToken().id === user_id ||
            verifyPermission("delete_comment")) && (
            <span id={`exclude-${index}-span`} onClick={() => handleDelete(id)}>
              Excluir
            </span>
          )}
        </Nickname>
        <span>{created_at}</span>
        <Comment>{comment}</Comment>
      </CommentInfo>
    </Container>
  );
};

export default CardComment;
